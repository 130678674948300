
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import sortBy from 'sort-by';
import { calcularRegistrosPagina, calcularTotalPaginas } from '../config/functions';
import '../css/cards.css';
import '../css/styles.css';
import { ArticuloModal } from './ArticuloModal';
import { Pagination } from './Pagination';
import precio from '../assets/img/precio.png';

export const Galeria = ({ lc, categoria }) => {

    const listaArt = lc;
    const [art, setArt] = useState({})
    const [filtroBusqueda, setFiltroBusqueda] = useState('');

    const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
    const [registrosPorPagina, setRegistrosPorPagina] = useState(8);
    const [paginaActual, setPaginaActual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        filtrarCateg(categoria, listaArt)
    }, [])

    const filtrarCateg = async (cat, lista) => {
        const listaFiltrada = lista.filter((a) => a.categoria === cat && a.estado === 'ACTIVO')
        if (listaFiltrada.length > 0) {
            setDocumentosFiltrados(listaFiltrada.sort(sortBy('consecutivoOk')));
        };
    }

    //---------Paginacion----------
    useEffect(() => {
        setTotalPaginas(calcularTotalPaginas(documentosFiltrados, registrosPorPagina));
    }, [documentosFiltrados, registrosPorPagina]);

    useEffect(() => {
        setRegistrosPaginaActual(calcularRegistrosPagina(documentosFiltrados, paginaActual, registrosPorPagina));
    }, [paginaActual, totalPaginas]);

    //----------------------------
    const [hoveredCard, setHoveredCard] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    //-----------------------------
    const filtrarProductos = (productos, consulta) => {
        if (!consulta) return productos;

        consulta = consulta.toLowerCase();
        return productos.filter((producto) => {
            return Object.values(producto).some(valor => {
                if (typeof valor === 'string') {
                    return valor.toLowerCase().includes(consulta);
                }
                return false;
            });
        });
    };

    useEffect(() => {
        const productosFiltrados = filtrarProductos(documentosFiltrados, filtroBusqueda);
        setRegistrosPaginaActual(calcularRegistrosPagina(productosFiltrados, paginaActual, registrosPorPagina));
    }, [filtroBusqueda, documentosFiltrados, paginaActual, registrosPorPagina]);



    return (
        <>
            <input
                type="text"
                className='mb-2 form-control'
                placeholder="Buscar..."
                value={filtroBusqueda}
                onChange={(e) => setFiltroBusqueda(e.target.value)}
            />
            <div className="row justify-content-center align-items-center">
                {registrosPaginaActual && registrosPaginaActual.map((lc, index) => (
                    lc.estado === 'ACTIVO' ?
                        <div key={lc.consecutivo + lc.id}
                            className='col-auto'>
                            <div className={`card card-galeria rounded shadow border-0 my-2 ${hoveredCard === index ? 'hovered' : ''}`}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => {
                                    setArt(lc)
                                    handleShow()
                                }}>

                                <div className="card-body p-4 text-center">
                                    <div className="container text-end mb-3 ">
                                        <span className="mrk">{lc.marca}</span>
                                    </div>
                                    <img
                                        src={lc.urlOk}
                                        alt=""
                                        style={{ maxHeight: '140px' }}
                                        className="img-fluid d-block mx-auto" />

                                    <table className='table table-sm align-middle'>
                                        <thead><tr><th colSpan={2} style={{ resize: 'none' }}>{lc.ref}</th></tr></thead>
                                        <tbody>
                                            {categoria === "CASE" ?
                                                <>
                                                    <tr>
                                                        <td><i className="fa-solid fa-square" /> Lateral:</td>
                                                        <td>{lc.estructuraLateral}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className="fa-regular fa-square-minus" /> Frontal:</td>
                                                        <td>{lc.estructuraFrontal}</td>
                                                    </tr>
                                                </>
                                                : null}


                                            {categoria === "NUC" || categoria === "COMPUTADORES" || categoria === "CORPORATIVOS" ?
                                                <>
                                                    <tr>
                                                        <td><i className="fa-solid fa-border-none" /></td>
                                                        <td>{lc.board}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className="fa-solid fa-microchip" /></td>
                                                        <td>{lc.procesador}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className="fa-solid fa-hard-drive" /></td>
                                                        <td>{lc.disco}</td>
                                                    </tr>

                                                </>
                                                : null}

                                            {categoria === "MONITORES" ?
                                                <>
                                                    <tr>
                                                        <td><i className="fa-solid fa-arrows-left-right"></i></td>
                                                        <td>{lc.tamanoImg}''</td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className="fa-solid fa-panorama"></i></td>
                                                        <td>{lc.resolucionPantalla}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className="fa-solid fa-tv"></i></td>
                                                        <td>{lc.tipoResolucion}</td>
                                                    </tr>
                                                </> : null}

                                            {categoria === "ACCESORIOS" || categoria === "SOFTWARE" ? <>
                                                <tr>
                                                    <td><i className="fa-solid fa-spell-check"></i></td>
                                                    <td>{lc.nombre}</td>
                                                </tr>
                                                <tr>
                                                    <td><i className="fa-regular fa-file-lines"></i></td>
                                                    <td className="truncate-text">{lc.descripcion.length > 60 ? lc.descripcion.slice(0, 60) + '...' : lc.descripcion}</td>
                                                </tr>
                                            </> : null}

                                            <tr>
                                                <td><i className="fa-solid fa-dollar-sign"></i></td>
                                                <td className="truncate-text">
                                                    {'$ ' + (Intl.NumberFormat("es-CO").format(lc.valor.length > 60 ? lc.valor.slice(0, 60) + '...' : lc.valor))}
                                                </td>
                                            </tr>

                                            {/* <tr>
                                                <td colSpan={2}>... ...</td>
                                            </tr> */}
                                        </tbody>
                                    </table>

                                    <ul className="list-inline small">
                                        <li className="list-inline-item m-0"><i className="fa fa-star text-success"></i></li>
                                        <li className="list-inline-item m-0"><i className="fa fa-star text-success"></i></li>
                                        <li className="list-inline-item m-0"><i className="fa fa-star text-success"></i></li>
                                        <li className="list-inline-item m-0"><i className="fa fa-star text-success"></i></li>
                                        <li className="list-inline-item m-0"><i className="fa fa-star text-success"></i></li>
                                    </ul>
                                </div>
                                {/* <div className="card-footer text-center">
                                    <button className="btn btn-secondary text-center"
                                        onClick={() => {
                                            history.push({ pathname: '/articulo', state: { articulo: { lc } } })
                                        }}>
                                        ...más información
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        :
                        <h4><strong className='text-primary'>{`No hay ${categoria} disponibles`}</strong></h4>
                ))}
            </div >

            <Pagination
                paginaActual={paginaActual}
                totalPaginas={totalPaginas}
                setPaginaActual={setPaginaActual}
                registrosPorPagina={registrosPorPagina}
                setRegistrosPorPagina={setRegistrosPorPagina}
                documentosFiltrados={documentosFiltrados}
                fixed={true}
            />

            <div className="modal fade" id="articuloModal" tabIndex="-1" aria-labelledby="articuloModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <ArticuloModal articulo={art} />
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton />
                <ArticuloModal articulo={art} />
            </Modal>

        </>
    )
}


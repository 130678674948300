import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from "react-router-dom";

const LogoutButton = ({ theme }) => {

    const history = useHistory();

    const handleLogout = () => {
        confirmAlert({
            title: 'Confirmar',
            message: '¿Estás seguro que deseas cerrar sesión?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => { deleteActiveSesion() }
                },
                { label: 'No' }
            ]
        });
    };

    const deleteActiveSesion = async () => {
        localStorage.removeItem("token");
        history.push("/");
    }

    return <div className={'update-var'}
        onClick={handleLogout}
        style={{ color: (theme === 'light' ? 'gray' : 'white'), fontWeight: '500' }}>
        LogOut
    </div>
};

export default LogoutButton;
import { initializeApp } from "firebase/app";

import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
} from "firebase/auth";

import {
  addDoc,
  collection,
  deleteDoc, doc, getDoc,
  getDocs,
  getFirestore,
  query, setDoc, updateDoc,
  where
} from "firebase/firestore";

import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(); //export para poder subir imagenes
export let usuarioActivo;
export let usuarioActivoEmail;
export let usuarioActivoPhoto;
export let usuarioActivoRol;

const database = getFirestore();
//------------------CRUD => Create Read Update Delete

//Guardar Base de datos
export const guardarDatabase = async (nombreColeccion, data) => {

  try {
    const respuesta = await addDoc(collection(database, nombreColeccion), data);

    const idResp = respuesta.id;
    return idResp;
  } catch (e) {
    throw new Error(e);
  }
};

//GUARDAR Base de datos con id propio campo por campo
export const guardarDatabaseId = async (nombreColeccion, idDocumento, data) => {
  try {
    const docRef = doc(database, nombreColeccion, idDocumento);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await updateDoc(docRef, data);
    } else {
      await setDoc(docRef, data);
    }

    return idDocumento;

  } catch (e) {
    throw new Error(e);
  }
};

//---------------------------------------------------------------------------------------------------

//Leer Base de Datos
export const consultarDatabase = async (nombreColeccion) => {

  try {
    const respuesta = await getDocs(
      query(collection(database, nombreColeccion))
    );
    const coleccionDatos = respuesta.docs.map((documento) => {
      const documentoTemporal = {
        idDocumento: documento.id, //id para actualizar
        ...documento.data(),
      };
      return documentoTemporal;
    });
    return coleccionDatos;
  } catch (e) {
    throw new Error(e);
  }
};

//consultar un solo documento
export const consultarDocumentoDatabase = async (nombreColeccion, idDocumento) => {

  try {
    const respuesta = await getDoc(doc(database, nombreColeccion, idDocumento));
    const documentoTemporal = {
      idDocumento: respuesta.id, //id para actualizar
      ...respuesta.data(),
    };
    return documentoTemporal;
  } catch (e) {
    throw new Error(e);
  }
};

//CONSULTAR un solo documento que contiene in objeto
export const consultarDocumentoDatabaseObject = async (nombreColeccion, idDocumento) => {

  try {
    const respuesta = await getDoc(doc(database, nombreColeccion, idDocumento));

    if (!respuesta.exists()) { return []; }

    const campos = []
    Object.keys(respuesta.data()).forEach(key => {
      if (!key.startsWith('idDocumento')) {
        const articulo = { consecutivo: key, ...respuesta.data()[key] };
        campos.push(articulo);
      }
    });

    const docConObjetoProcesado = campos
    return docConObjetoProcesado;

  } catch (e) {
    throw new Error(e);
  }
};

//CONSULTAR un solo documento que contiene in objeto
export const consultarDocumentoDatabaseObjectWhere = async (nombreColeccion, idDocumento, campo, valor) => {

  try {
    const respuesta = await getDoc(doc(database, nombreColeccion, idDocumento));

    if (!respuesta.exists()) { return []; }

    const campos = []
    Object.keys(respuesta.data()).forEach(key => {
      if (!key.startsWith('idDocumento')) {
        const articulo = { consecutivo: key, ...respuesta.data()[key] };
        campos.push(articulo);
      }
    });

    const docConObjetoProcesado = campos.filter(c => c[campo] === valor)
    return docConObjetoProcesado;

  } catch (e) {
    throw new Error(e);
  }
};

//consultar por where
export const consultarDocumentoWhere = async (
  nombreColeccion,
  terminoBusqueda,
  busqueda
) => {

  try {

    const respuesta = await getDocs(query
      (collection(database, nombreColeccion),
        where(terminoBusqueda, ">=", busqueda),
        where(terminoBusqueda, "<=", busqueda + "\uf8ff")
      )
    );

    const coleccionDatos = respuesta.docs.map((documento) => {
      const documentoTemporal = {
        idDocumento: documento.id, //id para actualizar
        ...documento.data(),
      };
      return documentoTemporal;
    });
    return coleccionDatos;
  } catch (e) {
    throw new Error(e);
  }
};

//consultar por where ====
export const consultarDocumentoWhereIgual = async (
  nombreColeccion,
  terminoBusqueda,
  busqueda,
) => {
  try {
    const respuesta = await getDocs(
      query(collection(database, nombreColeccion), where(terminoBusqueda, "==", busqueda))
    );

    const coleccionDatos = respuesta.docs.map((documento) => {
      const documentoTemporal = {
        idDocumento: documento.id, //id para actualizar
        ...documento.data(),
      };
      return documentoTemporal;
    });
    return coleccionDatos;
  } catch (e) {
    throw new Error(e);
  }
};

//consultar por where === &&
export const consultarDocumentoWhereIgualY = async (
  nombreColeccion,
  terminoBusqueda1,
  busqueda1,
  terminoBusqueda2,
  busqueda2
) => {
  try {
    const respuesta = await getDocs(
      query(collection(database, nombreColeccion), where(terminoBusqueda1, "==", busqueda1), where(terminoBusqueda2, "!=", busqueda2))
    );

    const coleccionDatos = respuesta.docs.map((documento) => {
      const documentoTemporal = {
        idDocumento: documento.id, //id para actualizar
        ...documento.data(),
      };
      return documentoTemporal;
    });
    return coleccionDatos;
  } catch (e) {
    throw new Error(e);
  }
};

export const consultarSesiones = async (empresa, usuario) => {
  try {
    const respuesta = await getDocs(
      query(collection(database, 'usuarios'),
        where('empresa', '==', empresa), where('activeSesion', '!=', '')
      )
    );

    const docEmp = await consultarDocumentoWhereIgual('empresas', 'idEmpresa', empresa)
    const licencias = docEmp[0].licencias !== '' && docEmp[0].licencias !== null ? parseInt(docEmp[0].licencias) : 0
    const sesiones = [false, 0, licencias]

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    let restoredSessions = 0;

    respuesta.docs.forEach(async (doc) => {
      const fechaSesion = new Date(doc.data().activeSesion);
      if (fechaSesion.getDate() < yesterday.getDate()) {
        const userDocRef = doc('usuarios', doc.id);
        await setDoc(userDocRef, { activeSesion: null }, { merge: true });
        restoredSessions++;
      }
    });

    const coleccionDatos = respuesta.docs.map((documento) => {
      const fechaSesion = new Date(documento.data().activeSesion);
      if (documento.data().correo === usuario && fechaSesion.getDate() === new Date().getDate()) {
        sesiones[0] = true;
      }
      const documentoTemporal = { idDocumento: documento.id, ...documento.data() };
      return documentoTemporal;
    });

    sesiones[1] = coleccionDatos.length - restoredSessions;

    return sesiones;
  } catch (e) {
    throw new Error(e);
  }
};


//---------------------------------------------------------------------------------------------------

//Actualizaciòn de un documento en Base de datos
export const actualizarDocumentoDatabase = async (
  nombreColeccion, idDocumento, data
) => {
  try {
    await updateDoc(doc(database, nombreColeccion, idDocumento),
      data);

  } catch (e) {
    throw new Error(e);

  }
};

//ACTUALIZACION de un documento en Base de datos con el CAMPO
export const actualizarDocumentoDatabaseIdCampo = async (nombreColeccion, idDocumento, nombreCampo, nuevoValor) => {
  try {
    const docRef = doc(database, nombreColeccion, idDocumento);
    const documentoActual = await getDoc(docRef);

    if (documentoActual.exists()) {
      const datos = documentoActual.data();
      datos[nombreCampo] = nuevoValor;
      await updateDoc(docRef, datos);
      return datos;
    } else {
      throw new Error('El documento no existe');
    }
  } catch (e) {
    throw new Error(e);
  }
};

//-----------------------------------------------------------------------------------------------------

//Eliminar de un documento en Base de datos
export const eliminarDocumentoDatabase = async (
  nombreColeccion,
  idDocumento
) => {

  try {
    const respuesta = await deleteDoc(doc(database, nombreColeccion, idDocumento));
    
  } catch (e) {
    throw new Error(e);
  }
};

//ELIMINAR CAMPO de un documento en Base de datos
export const eliminarDocumentoDatabaseCampo = async (nombreColeccion, idDocumento, nombreCampo) => {
  try {
    const docRef = doc(database, nombreColeccion, idDocumento);
    const documentoActual = await getDoc(docRef);

    if (documentoActual.exists()) {
      const datos = documentoActual.data();

      if (datos[nombreCampo]) {
        delete datos[nombreCampo];
        await setDoc(docRef, datos);
        return `${nombreCampo} eliminado correctamente.`;
      } else {
        throw new Error(`${nombreCampo} no existe en el documento.`);
      }
    } else {
      throw new Error('El documento no existe');
    }
  } catch (e) {
    throw new Error(e);
  }
};

//-----------------login firebase-----------------------

//Crear Usuarios con FireBase
export const crearUsuario = async (email, password) => {
  try {
    const credencialesUsuario = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    /* console.log(credencialesUsuario)
        console.log(credencialesUsuario.user)
        console.log(credencialesUsuario.user.uid) */
    const user = {
      id: credencialesUsuario.user.uid,
      email: credencialesUsuario.user.email,
    };
    //guardarDatabase("prueba_react", user); //Para llevar el usuario a la base de datos
    return user;
  } catch (e) {
    throw new Error(e);
  }
};

//Login de Usuarios con FireBase auth
export const logInUsuario = async (email, password) => {
  try {
    const credencialesUsuario = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    /* console.log(credencialesUsuario)
        console.log(credencialesUsuario.user)
        console.log(credencialesUsuario.user.uid) */

    const user = {
      id: credencialesUsuario.user.uid,
      email: credencialesUsuario.user.email,
    };

    usuarioActivo = user;

    return credencialesUsuario.user;
  } catch (e) {
    throw new Error(e);
  }
};

//Login PopUP google
export const logInUsuarioPopup = async () => {
  try {
    let respuesta = await signInWithPopup(auth, new GoogleAuthProvider());

    const user = {
      id: respuesta.user.uid,
      email: respuesta.user.email,
    };

    console.log(user.id);
    console.log(user.email);

    return user.email;
  } catch (e) {
    throw new Error(e);
  }
};

//---------------------log Out---------------------------------------------------------------------------

//LogOut de Usuarios con FireBase auth
export const logOutUsuario = async () => {
  try {
    let respuesta = await signOut(auth);
    usuarioActivo = undefined;
    usuarioActivoEmail = undefined;
    usuarioActivoRol = "Sin Validación";
  } catch (e) {
    throw new Error(e);
  }
};


//-------------------------------------------------------------------------------Datos Usuario actual auth
export const datosUsuario = async () => {
  try {
    const user = auth.currentUser;
    /* console.log(user) */

    //validacion de usuario, recordar undefined is falsy
    if (user) {
      /* console.log('activo: ', user.uid) */
      return user.email;
    } else {
      /* console.log('no activo: ', user); */
      return undefined;
    }
  } catch (e) {
    throw new Error(e);
  }
};

//consultar tipo Usuario
export const consultarTipoUsuario = async (email) => {
  try {
    let respuesta = await getDocs(
      query(collection(database, "hdlb_users"), where("email", "==", email))
    );

    const coleccionDatos = respuesta.docs.map((documento) => {
      //console.log(documento.data().rol)
      usuarioActivoRol = documento.data().rol;
      usuarioActivo = documento.data().nombres;
    });
    // return usuarioActivoRol
  } catch (e) {
    throw new Error(e);
  }
};

//siempre estar buscando auth
onAuthStateChanged(auth, (user) => {
  if (user) {
    /* console.log(user); */
    usuarioActivo = user.displayName;
    usuarioActivoEmail = user.email;
    usuarioActivoPhoto = user.photoURL;

    consultarTipoUsuario(usuarioActivoEmail);
    /* console.log(usuarioActivoPhoto) */
  } else {
    /* console.log(user); */
    usuarioActivo = undefined;
    //console.log(usuarioActivo)
  }
});


//-------------------------------------------------Imagenes

// Subir una imagen a Firebase Storage
export const subirImagen = async (rutaEnFirestore, imagen) => {
  const storageRef = ref(storage, rutaEnFirestore);

  try {
    await uploadBytes(storageRef, imagen);
    console.log('Imagen subida exitosamente a Firebase Storage');
  } catch (error) {
    throw new Error('Error al subir la imagen:', error);
  }
};

// Eliminar una imagen de Firebase Storage
export const eliminarImagen = async (rutaEnFirestore) => {
  const storageRef = ref(storage, rutaEnFirestore);

  try {
    // Verificar si la imagen existe antes de intentar eliminarla
    const existe = await existeImagen(rutaEnFirestore);

    if (existe) {
      await deleteObject(storageRef);
      console.log('Imagen eliminada de Firebase Storage');
    } else {
      console.log('Imagen no encontrada en Firebase Storage.');
    }
  } catch (error) {
    throw new Error('Error al eliminar la imagen:', error);
  }
};

// Actualizar una imagen en Firebase Storage
export const actualizarImagen = async (rutaEnFirestore, nuevaImagen) => {
  const storageRef = ref(storage, rutaEnFirestore);

  try {
    // Verificar si la imagen existe antes de eliminarla
    const existe = await existeImagen(rutaEnFirestore);

    if (existe) {
      await deleteObject(storageRef);
    } else {
      console.log('Imagen no encontrada en Firebase Storage.');
    }
    // Subir la nueva imagen
    await uploadBytes(storageRef, nuevaImagen);
    console.log('Imagen actualizada exitosamente en Firebase Storage');
  } catch (error) {
    throw new Error('Error al actualizar la imagen:', error);
  }
};

// Verificar si una imagen existe en Firebase Storage
export const existeImagen = async (rutaEnFirestore) => {
  const storageRef = ref(storage, rutaEnFirestore);

  try {
    await getDownloadURL(storageRef);
    return true;
  } catch (error) {
    // Si getDownloadURL lanza un error, la imagen no existe
    return false;
  }
};

// Obtener la URL de descarga de una imagen en Firebase Storage
export const obtenerURLImagen = async (rutaEnFirestore) => {
  const storageRef = ref(storage, rutaEnFirestore);

  try {
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    throw new Error('Error al obtener la URL de la imagen:', error);
  }
};




export const createUser = async (email/* , password */) => {
  try {
    createUserWithEmailAndPassword(getAuth(app), email, '123456'/* password */)
  } catch (e) {
    throw new Error(e);
  }
}

export const signInUser = async (email, password) => {
  return signInWithEmailAndPassword(getAuth(app), email, password);
}

export const changePwd = async (p) => {
  const auth = getAuth();
  const user = auth.currentUser;
  const newPassword = p;

  updatePassword(user, newPassword)/* .then(() => {

  }).catch((error) => {

  }); */
}
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export const RutaPrivada = ({ component: Component, ...rest }) => {

    const usuarioActivo = JSON.parse(localStorage.getItem('token')).userName
    const usuarioTipo = JSON.parse(localStorage.getItem('token')).tipo
    const usuarioEstado = JSON.parse(localStorage.getItem('token')).estado
    
    return (
        <Route {...rest}>
            {
                usuarioActivo !== '' && usuarioEstado === 'ACTIVO' ?
                    <Component /> :
                    <Redirect to="/" />
            }
        </Route>
    )
}

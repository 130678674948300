import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import logo from '../assets/img/logo.png';
import bcrypt from 'bcryptjs';
import { Link } from 'react-scroll'

import {
    actualizarDocumentoDatabase,
    changePwd,
    consultarDocumentoWhereIgual,
    logOutUsuario,
    signInUser
} from "../config/firebase";

function NavBar() {


    const fechaActual = new Date();
    const fechaFormateada = fechaActual.toLocaleDateString("es-CO");
    const fechaModificacion = fechaFormateada;
    const history = useHistory();

    const [user, setUser] = useState("");
    const [pwd, setPwd] = useState("");
    const [clave1, setClave1] = useState("");
    const [clave2, setClave2] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [idDocumento, setIdDocumento] = useState("");

    const [cambioClave, setCambioClave] = useState(0);

    //---------------------------------
    const setToken = (token) => {
        if (token) {
            setAuthToken(token);
            localStorage.setItem("token", JSON.stringify(token));
        } else {
            setAuthToken(null);
            localStorage.removeItem("token");
        }
    };

    // ----------- cambio de clave ----------
    const cambiarClave = async () => {

        if (clave1 !== '' && clave2 !== '') {

            if (clave1 === clave2) {
                const nuevaClave = bcrypt.hashSync(clave1, 10);

                const enviarUsuario = {
                    clave: nuevaClave,
                    cambioClave: false,
                    fechaModificacion: fechaModificacion,
                };

                await actualizarDocumentoDatabase(
                    "usuarios",
                    idDocumento,
                    enviarUsuario
                );
                await changePwd(clave1)

                logOutUsuario()

                setCambioClave(0)
                setClave1('')
                setClave2('')
                setPwd('')

                toast.success("Contraseña modificada de manera correcta, ya puede ingresar al aplicativo");
            } else {
                toast.warning('Las contraseñas no son iguales')
            }
        } else {
            toast.warning('Diligencie ambos campos')
        }
    };

    //---------------**********--------------
    // const [recaptchaValue, setRecaptchaValue] = useState(null);
    // const handleRecaptchaChange = (value) => { setRecaptchaValue(value) };

    /*--------------Login-----------------*/
    const handleClickLogin = async () => {

        try {
            const prueba = await signInUser(user, pwd);
            const ok = prueba.user.uid;

            if (ok !== null) {

                if (user !== "" && pwd !== "") {
                    const listaTemporal = await consultarDocumentoWhereIgual(
                        "usuarios",
                        "correo",
                        user
                    );

                    if (listaTemporal.length === 1) {
                        setIdDocumento(listaTemporal[0].idDocumento);
                        if (listaTemporal[0].cambioClave) {
                            setCambioClave(1);
                            setUser('')
                            toast.info('Se debe realizar asignación de contraseña')
                        } else {

                            const hpwd = bcrypt.compareSync(pwd, listaTemporal[0].clave);
                            if (hpwd === true && listaTemporal[0].estado === "ACTIVO") {
                                toast.success("Bienvenido");
                                setToken({
                                    userName: listaTemporal[0].nombre,
                                    tipo: listaTemporal[0].rol,
                                    estado: listaTemporal[0].estado,
                                    em: listaTemporal[0].correo,
                                });
                                history.push("/monitores");

                            } else {
                                toast.warning("El usuario no se encuentra autorizado");
                            }
                        }
                    } else {
                        toast.warning("Los datos ingresados son incorrectos");
                    }
                }
            }
        } catch (error) {
            // console.log(error.message)
            toast.warning('Error durante la autenticación:', error.message);
        }
    }

    return (
        <>

            <ToastContainer position="bottom-right" />
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" aria-label="Offcanvas navbar large">

                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={logo}
                            alt="Logo" width="50" height="50"
                            className="d-inline-block align-text-top" />
                    </a>
                    <a className="navbar-brand" href="#">Heritage Group</a>
                    <button className="navbar-toggler"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar2"
                        aria-controls="offcanvasNavbar2"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="offcanvas offcanvas-end text-bg-dark"
                        tabIndex="-1"
                        id="offcanvasNavbar2"
                        aria-labelledby="offcanvasNavbar2Label">
                        <div className="offcanvas-header text-center">
                            <h5 className="offcanvas-title" id="offcanvasNavbar2Label">
                                <img src={logo} alt="Logo" width="70" height="70" />
                                {/* <h5 className='mt-2'>Heritage Group</h5> */}
                            </h5>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li className="nav-item ">
                                    <a className="nav-link color-hg" aria-current="page" href="#">Inicio</a>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="productos"
                                        spy={true}
                                        smooth={true}
                                        offset={-100}
                                        duration={500}
                                    >
                                        Productos
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="#">Catálogo</a>
                                </li> */}
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="nosotros"
                                        spy={true}
                                        smooth={true}
                                        offset={-100}
                                        duration={500}
                                    >
                                        Nosotros
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="experiencia"
                                        spy={true}
                                        smooth={true}
                                        offset={-100}
                                        duration={500}
                                    >
                                        Experiencia
                                    </Link>
                                </li>
                                <li className="nav-item me-3">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="footer"
                                        spy={true}
                                        smooth={true}
                                        offset={-100}
                                        duration={500}
                                    >
                                        Contáctenos
                                    </Link>
                                </li>
                                <li className="nav-item me-3">
                                    <a className="nav-link" href="#"
                                        aria-label="Canvas"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasLogin"
                                        aria-controls="offcanvasLogin">LogIn</a>
                                </li>
                            </ul>
                            {/* <form className="d-flex mt-3 mt-lg-0" role="search">
                                <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                <button className="btn btn-outline-success" type="submit">Search</button>
                            </form> */}
                        </div>
                    </div>
                </div>
            </nav>

            {/* offcanvas */}
            <div className="container ">

                <div className="offcanvas offcanvas-end "
                    tabIndex="-1"
                    id="offcanvasLogin"
                    aria-labelledby="offcanvasLoginLabel">

                    <div className="offcanvas-header">
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body">

                        <div className="login-html">
                            <div className="row text-center">
                                <div className="col"><img src={logo}
                                    alt="Logo" width="150" height="150" className="logo" /></div>
                            </div>

                            <hr style={{ color: 'white', backgroundColor: 'white', height: '3px' }} className="m-2" />
                            {cambioClave === 0
                                ?
                                <>
                                    <input
                                        type="text"
                                        placeholder="User Name"
                                        className="form-control text-center mb-2"
                                        value={user}
                                        onChange={(e) => setUser(e.target.value)}
                                    />

                                    <input
                                        type="password"
                                        className="form-control text-center mb-3"
                                        placeholder="Password"
                                        data-type="password"
                                        value={pwd}
                                        onChange={(e) => setPwd(e.target.value)}
                                    />

                                    {/* <div className="row text-center mb-3">
                                        <div className="col d-flex justify-content-center align-items-center">
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                onChange={handleRecaptchaChange}
                                            />
                                        </div>
                                    </div> */}
                                </> :
                                <>
                                    <input
                                        type="text"
                                        className="form-control text-center mb-2"
                                        data-type="password"
                                        placeholder={"Ingrese Contraseña"}
                                        value={clave1}
                                        onChange={(e) => setClave1(e.target.value)}
                                    />
                                    <input
                                        type="password"
                                        className="form-control text-center mb-2"
                                        data-type="password"
                                        placeholder={"Repetir Contraseña"}
                                        value={clave2}
                                        onChange={(e) => setClave2(e.target.value)}
                                    />
                                </>
                            }

                            {(user !== "" && pwd !== "" || (clave1 !== '' && clave2 !== '')) //&& recaptchaValue
                                ? <div className="container">
                                    <div className="row">
                                        <button
                                            className="btn btn-outline-secondary"
                                            data-bs-dismiss="offcanvas"
                                            aria-label="SignIn"
                                            onClick={cambioClave === 0 ? handleClickLogin : cambiarClave}>
                                            {cambioClave === 0 ? 'Sign In' : 'Asignar clave'}
                                        </button>
                                    </div>
                                </div>
                                : null
                            }

                            {/* {user !== "" && recaptchaValue
                                ? 
                                <div className="container">
                                    <div className="row">
                                        <button
                                            className="btn btn-outline-secondary"
                                            data-bs-dismiss="offcanvas"
                                            aria-label="SignIn"
                                            onClick={handleClickLogin}>
                                            Sign In
                                        </button>
                                    </div>
                                </div>
                                : null
                            } */}

                        </div>
                        {/* </div> */}
                    </div>
                </div>

            </div>
            {/* offcanvas */}
        </>
    )
}

export default NavBar

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import sortBy from "sort-by";
import uniqid from "uniqid";
import { Filtrar } from "./Filtrar";
import { LoaderES } from "./LoaderES";
import ModalCoumnas from "./ModalColumnas";
import { Pagination } from "./Pagination";

import {
    actualizarDocumentoDatabase,
    consultarDatabase,
    eliminarDocumentoDatabase,
    guardarDatabase
} from "../config/firebase";

import {
    buscarColumnas,
    calcularRegistrosPagina,
    calcularTotalPaginas,
    crearArregloInicial,
    ordenar,
    toggleOpen,
    validarMobil,
} from "../config/functions";

export const Consecutivos = () => {
    const user = JSON.parse(localStorage.getItem("token")).userName;

    const broken = JSON.parse(localStorage.getItem("broken"));
    const collapsed = JSON.parse(localStorage.getItem("collapsed"));

    const fechaActual = new Date();
    const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

    // Paginacion
    const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
    const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
    const [paginaActual, setPaginaActual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(0)
    const [registrosPaginaActual, setRegistrosPaginaActual] = useState([])

    // General
    const [isOpen, setIsOpen] = useState(null);
    const [add, setAdd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [mobile, setMobile] = useState(true);

    // Consecutivo
    const [listaConsecutivos, setListaConsecutivos] = useState([]);
    const [idDocumento, setIdDocumento] = useState("");
    const [prefijo, setPrefijo] = useState("")
    const [descripcion, setDescripcion] = useState("")
    const [valorActual, setValorActual] = useState(0)
    const [estado, setEstado] = useState("")
    const [consecutivoEliminar, setConsecutivoEliminar] = useState("");

    // Usuario
    const [usuarioCreacion, setUsuarioCreacion] = useState(user);
    const [fechaCreacion, setFechaCreacion] = useState(fechaFormateada);
    const usuarioModificacion = user;
    const fechaModificacion = fechaFormateada;

    // Columnas
    const [col2, setCol2] = useState(true)
    const [col3, setCol3] = useState(true)
    const [col4, setCol4] = useState(true)
    const [countOrdenar, setCountOrdenar] = useState(0)


    /*************** Funciones ***********************/
    useEffect(() => {
        cargarConsecutivos()
        buscarColumnas([2, 3, 4], 'concec', setCol2, setCol3, setCol4)
        validarMobil(setMobile)
    }, []);

    useEffect(() => {
        if (add) {
            resetStates()
        }
    }, [add])


    useEffect(() => {
        setIsOpen(crearArregloInicial(registrosPaginaActual.length))
    }, [registrosPaginaActual])

    const cargarConsecutivos = async () => {
        setIsLoading(true)
        try {
            const listaTemporal = await consultarDatabase("consecutivos");

            if (listaTemporal.length > 0) {
                setListaConsecutivos(listaTemporal.sort(sortBy('prefijo')));
                setDocumentosFiltrados(listaTemporal.sort(sortBy('prefijo')));
            } else {
                const temporal = [{
                    prefijo: '',
                    descripcion: '',
                    valorActual: '',
                    estado: '',
                }]
                setListaConsecutivos(temporal);
                setDocumentosFiltrados(temporal)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    };

    const handleClickAdicionar = async () => {

        const respuesta = listaConsecutivos.find(m => m.prefijo === prefijo);

        if (respuesta) {
            toast.info("El consecutivo  : " + prefijo + " ya se encuentra registrado, intente de nuevo");
            resetStates();
        } else {

            const enviarArticulo = {
                id: uniqid("consec-"),
                prefijo: prefijo.toUpperCase(),
                descripcion: descripcion.toUpperCase(),
                valorActual: parseInt(valorActual),
                estado: estado,
                fechaCreacion: fechaCreacion,
                usuarioCreacion: usuarioCreacion.toUpperCase(),
            };

            if (prefijo === "" || descripcion === "" || estado === "" || valorActual === "" || valorActual === 0) {
                toast.warning("Diligencie todos los campos de manera correcta");
            } else {
                await guardarDatabase("consecutivos", enviarArticulo);
                await cargarConsecutivos();
                toast.success("Consecutivo agregado de manera correcta");
                resetStates();
            }
        }
    };

    const handleClickTraerConsec = (m) => {
        setIdDocumento(m.idDocumento)
        setPrefijo(m.prefijo)
        setDescripcion(m.descripcion)
        setValorActual(m.valorActual)
        setEstado(m.estado)
    };

    const handleClickModificarBd = async () => {

        const enviarArticulo = {
            prefijo: prefijo,
            descripcion: descripcion,
            valorActual: parseInt(valorActual),
            estado: estado,
            fechaModificacion: fechaModificacion,
            usuarioModificacion: usuarioModificacion.toUpperCase(),
        };

        if (idDocumento !== "" && idDocumento !== undefined) {
            setIsLoading(true);
            try {
                await actualizarDocumentoDatabase("consecutivos", idDocumento, enviarArticulo);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
            toast.success("Consecutivo Modificado correctamente");
            await cargarConsecutivos();
            resetStates();
        } else {
            toast.warning("Seleccione un consecutivo valido para modificarlo");
        }
    };

    const handleClickEliminar = async (a) => {
        if (a.idDocumento !== "" && a.idDocumento !== undefined) {
            setIsLoading(true);
            try {
                await eliminarDocumentoDatabase("consecutivos", a.idDocumento);
                toast.success("Consecutivo " + a.prefijo + ", se elimino correctamente");
                cargarConsecutivos()

            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        } else {
            toast.warning("Seleccione un consecutivo para eliminar");
        }
    };

    const resetStates = () => {
        setIdDocumento("")
        setPrefijo("")
        setDescripcion("")
        setValorActual("")
        setEstado("")
    };

    //------------------Paginacion-------------------
    useEffect(() => {
        setTotalPaginas(calcularTotalPaginas(documentosFiltrados, registrosPorPagina));
    }, [documentosFiltrados, registrosPorPagina]);

    useEffect(() => {
        setRegistrosPaginaActual(calcularRegistrosPagina(documentosFiltrados, paginaActual, registrosPorPagina));
    }, [paginaActual, totalPaginas, countOrdenar]);


    return (
        <>
            {isLoading
                ? <LoaderES />
                : <>
                    <div className="fixed-top "
                        style={{
                            backgroundColor: 'white',
                            marginLeft: broken || mobile ? '10px' : (!collapsed ? '260px' : '90px'),
                            marginRight: broken || mobile ? '10px' : (!collapsed ? '15px' : '15px'),
                            transition: 'margin-left 0.3s ease',
                            top: '46px',
                            zIndex: 1
                        }}
                    >
                        {/* Botones Opciones */}
                        <div className="row mb-2">
                            <div className="col-sm-4 align-self-start text-start">

                                {/* Adicionar */}
                                <button
                                    className="btn btn-sm"
                                    data-bs-toggle="modal"
                                    data-bs-target="#ModAdiConsec"
                                    title="Crear Consecutivo"
                                    onClick={() => setAdd(true)} >
                                    <i className="fa-solid fa-plus" />
                                </button>

                                {/* Columnas */}
                                <button
                                    className="btn btn-sm"
                                    title="Seleccionar Columnas"
                                    data-bs-toggle="modal"
                                    data-bs-target="#ModificarColumnas"
                                    id="modalcolumnas" >
                                    <i className="fa-solid fa-table-columns" />
                                </button>

                                {/* Actualizar */}
                                <button
                                    title="Actualizar Datos"
                                    className="btn btn-sm"
                                    onClick={() => cargarConsecutivos()}>
                                    <i className="fa-solid fa-rotate" />
                                </button>
                            </div>

                            {/* filtrar */}
                            <div className="col-sm-8">
                                <Filtrar
                                    tabla="myTable"
                                    documentos={listaConsecutivos}
                                    setDocumentosFiltrados={setDocumentosFiltrados}
                                    setRegistrosFiltrados={setRegistrosPaginaActual}
                                    registrosPorPagina={registrosPorPagina} />
                            </div>
                        </div>
                    </div>

                    <hr className={" mt-5 " + (mobile === true ? ' mb-5' : '')} />
                    {mobile
                        ?
                        /* Mobile */
                        <>
                            {registrosPaginaActual?.map((c, index) => (
                                <div key={c.prefijo + c.id}>
                                    <table className="table table-sm table-hover table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '50px', resize: 'none' }}>
                                                    <button className="btn btn-sm"
                                                        type="button"
                                                        onClick={() => toggleOpen(index, isOpen, setIsOpen)}

                                                        data-bs-target={"#collapseItem" + c.prefijo}
                                                        aria-expanded={isOpen[index]}
                                                        aria-controls="collapseItem" >
                                                        <i className={`fa-solid ${isOpen[index] ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                                                    </button>
                                                </th>
                                                <th style={{ width: '65px', resize: 'none' }}>{c.prefijo}</th>
                                                <td style={{ minWidth: '60px', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', resize: 'none' }}>
                                                    <span className="truncate-text">
                                                        {c.descripcion.length > 50 ? c.descripcion.slice(0, 50) + '...' : c.descripcion}
                                                    </span>
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>


                                    <div className={`collapse card card-body ${isOpen[index] ? 'show' : ''}`} id={"collapseItem" + c.prefijo}>
                                        <table className="table table-sm">
                                            <tbody >
                                                <tr>
                                                    <td></td>
                                                    <th style={{ resize: 'none' }} className="text-start">Prefijo </th>
                                                    <td className="text-start">{c.prefijo}</td>
                                                </tr>

                                                {!col2 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Descripcion </th>
                                                        <td className="text-start">{c.descripcion}</td>
                                                    </tr>
                                                }

                                                {!col3 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Val. Actual </th>
                                                        <td className="text-start">{c.valorActual}</td>
                                                    </tr>
                                                }

                                                {!col4 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Estado </th>
                                                        <td className="text-start">{c.estado}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td></td>
                                                    <th style={{ resize: 'none' }} className="text-start">Actions </th>
                                                    <td className="text-center">
                                                        <div className="btn-group">

                                                            {/* Btn Modificar */}
                                                            <button
                                                                className="btn btn-sm btn-outline-info"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#ModAdiConsec"
                                                                title="Modificar Item Seleccionado"
                                                                onClick={() => {
                                                                    setAdd(false)
                                                                    handleClickTraerConsec(c)
                                                                }}>
                                                                <i className="fa-regular fa-pen-to-square" />
                                                            </button>

                                                            {/* Btn Eliminar */}
                                                            <button
                                                                className="btn btn-sm btn-outline-danger"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#EliminarConsec"
                                                                title="Eliminar Item Seleccionado"
                                                                onClick={() => setConsecutivoEliminar(c)
                                                                }>
                                                                <i className="fa-regular fa-trash-can" />
                                                            </button>

                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            ))}
                        </>
                        /* Mobile */
                        :
                        /* Desktop */
                        <div className="table-responsive">
                            <table
                                id="myTable"
                                className="table table-hover table-striped table-sm table-bordered align-middle">
                                <thead className="text-center">
                                    <tr >
                                        <th onClick={() => ordenar('prefijo', countOrdenar, documentosFiltrados, setListaConsecutivos, setCountOrdenar)}>Prefijo</th>
                                        <th onClick={() => ordenar('descripcion', countOrdenar, documentosFiltrados, setListaConsecutivos, setCountOrdenar)} className={col2 ? '' : 'd-none'}>Descripcion</th>
                                        <th onClick={() => ordenar('valorActual', countOrdenar, documentosFiltrados, setListaConsecutivos, setCountOrdenar)} className={col3 ? '' : 'd-none'}>Val. Actual</th>
                                        <th onClick={() => ordenar('estado', countOrdenar, documentosFiltrados, setListaConsecutivos, setCountOrdenar)} className={col4 ? '' : 'd-none'}>Estado</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center" id="tabla_articulos">

                                    {registrosPaginaActual.length === 1 && registrosPaginaActual[0].prefijo === '' ? null

                                        : registrosPaginaActual?.map((c) => (
                                            <tr key={c.prefijo + c.id}>
                                                <td>{c.prefijo}</td>
                                                <td className={col2 ? '' : 'd-none'}>{c.descripcion}</td>
                                                <td className={col3 ? '' : 'd-none'}>{c.valorActual}</td>
                                                <td className={col4 ? '' : 'd-none'}>{c.estado}</td>

                                                {/* Btns */}
                                                <td className="text-center">
                                                    <div className="btn-group">

                                                        {/* Btn Modificar */}
                                                        <button
                                                            className="btn btn-sm btn-outline-info"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#ModAdiConsec"
                                                            title="Modificar Item Seleccionado"
                                                            onClick={() => {
                                                                setAdd(false)
                                                                handleClickTraerConsec(c)
                                                            }}>
                                                            <i className="fa-regular fa-pen-to-square" />
                                                        </button>

                                                        {/* Btn Eliminar */}
                                                        <button
                                                            className="btn btn-sm btn-outline-danger"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#EliminarConsec"
                                                            title="Eliminar Item Seleccionado"
                                                            onClick={() => setConsecutivoEliminar(c)
                                                            }>
                                                            <i className="fa-regular fa-trash-can" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        /* Desktop */
                    }

                    <Pagination
                        paginaActual={paginaActual}
                        totalPaginas={totalPaginas}
                        setPaginaActual={setPaginaActual}
                        registrosPorPagina={registrosPorPagina}
                        setRegistrosPorPagina={setRegistrosPorPagina}
                        documentosFiltrados={documentosFiltrados}
                    />

                    {/* + Modal de Modificar // Adicionar monitores*/}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="ModAdiConsec"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className={add ? "modal-header bg-primary text-light justify-content-center" : "modal-header bg-info text-secondary justify-content-center"}>
                                    <h5 className="modal-title fs-5">
                                        {add ? "Crear " : "Modificar "} Consecutivo
                                    </h5>
                                </div>

                                {/* Formulario */}
                                <div className="modal-body">

                                    {/* Prefijo */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Prefijo:</span>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled={!add}
                                            value={prefijo}
                                            onChange={(e) => setPrefijo(e.target.value.toUpperCase())}
                                        />
                                    </div>

                                    {/* Descripcion  */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Descripcion:</span>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={descripcion}
                                            onChange={(e) => setDescripcion(e.target.value.toUpperCase())}
                                        />
                                    </div>

                                    {/* Valor actual  */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Valor {add ? "Inicial" : "Actual"}</span>
                                        <input
                                            className="form-control"
                                            type="number"
                                            min={0}
                                            value={valorActual}
                                            onChange={(e) => setValorActual(e.target.value)}
                                        />
                                    </div>

                                    {/* Estado */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Estado: </span>
                                        <select
                                            className="form-select"
                                            value={estado}
                                            onChange={(e) => setEstado(e.target.value)}>
                                            <option value="" disabled>Seleccione </option>
                                            <option value="ACTIVO">Activo</option>
                                            <option value="INACTIVO">Inactivo</option>
                                        </select>
                                    </div>

                                </div>

                                <div className="modal-footer">
                                    {prefijo && descripcion && valorActual ?
                                        <button
                                            type="button"
                                            className={add ? "btn btn-sm btn-primary text-light" : "btn btn-sm btn-info text-secondary"}
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                add ?
                                                    handleClickAdicionar()
                                                    : handleClickModificarBd()
                                            }}
                                        >  {add ? "Crear" : "Modificar"}
                                        </button>
                                        : null}

                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        data-bs-dismiss="modal"
                                        onClick={() => resetStates()}
                                    > Cancelar </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal de Modificar // Adicionar */}

                    {/* +Modal Eliminar */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="EliminarConsec"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className="modal-header bg-danger text-white justify-content-center">
                                    <h5 className="modal-title  " id="staticBackdropLabel3">
                                        Alerta Eliminar Consecutivo
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <div className="modal-body text-center">
                                        <p>
                                            Está seguro de eliminar el consecutivo: <strong> {' ' + consecutivoEliminar.prefijo}</strong>, <br />
                                            recuerde que esta acción no se puede devolver
                                        </p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        data-bs-dismiss="modal"
                                        id="btnEliminarArticulo"
                                        onClick={() => handleClickEliminar(consecutivoEliminar)}
                                    >
                                        Si, eliminar
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal Eliminar */}

                    {/* +Modal Columnas */}
                    <ModalCoumnas
                        columnas={['Descripcion', 'Val. Actual', 'Estado']}
                        identificador={'consec'}
                        col2={col2} col3={col3} col4={col4}
                        setCol2={setCol2} setCol3={setCol3} setCol4={setCol4} />
                </>
            }
        </>
    )
}

import React from 'react'
import cpe from '../assets/img/cpe.png'
import alianza from '../assets/img/alianza.png'
import blackhg from '../assets/img/blackhg.png'
import bancomp4 from '../assets/mp4/banco.mp4'

const About = () => {
    return (
        <>
            <hr className="featurette-divider" />

            <div className="row align-items-center featurette" id='nosotros'>
                <div className="col-md-7" >
                    <h2 className="featurette-heading fw-normal lh-1" > Nosotros<span className="text-body-secondary"> </span></h2>
                    <p className="lead">
                        Somos una empresa fundada en 2009, como un fabricante local de equipos de cómputo Local OEM y
                        mayorista de tecnología de las principales marcas en el mercado colombiano.
                    </p>
                    <p className="lead">
                        Contamos con más de 13 años de experiencia en el mercado local y contamos con las más altas
                        certificaciones de nuestros socios de negocios a nivel mundial, como lo es Partner Titanium de
                        Intel como Local OEM para el mercado colombiano.
                    </p>
                </div>
                <div className="col-md-5">
                    <img src={blackhg} alt="blackhg" className="img-fluid mx-auto" /* width="450" height="400" */ />
                </div>
            </div>

            <hr className="featurette-divider" />

            <div className="row align-items-center featurette" id='experiencia'>
                <div className="col-md-7 order-md-2">
                    <h2 className="featurette-heading fw-normal lh-1">Nuestra<span className="text-body-secondary"> Experiencia</span></h2>
                    <p className="lead">
                        Nuestra marca Heritage, se destaca por contar con alianzas estratégicas con los mejores partners de tecnología a nivel
                        mundial como lo son Intel, Gigabyte, Adata, entre otros, que garantizan que la calidad de nuestros productos sean la mejor.
                    </p>
                    <p className="lead">
                        Empresa 100% Colombiana
                    </p>
                </div>
                <div className="col-md-5 order-md-1">
                    <img src={alianza} alt="alianza" className="img-fluid mx-auto" /* width="450" height="400" */ />
                </div>
            </div>

            <hr className="featurette-divider" />

            <div className="row align-items-center featurette" id='estado'>
                <div className="col-md-7">
                    <h2 className="featurette-heading fw-normal lh-1"> Contratación
                        <span className="text-body-secondary"> con el Estado</span>
                    </h2>
                    <p className="lead">
                        Caso Computadores para Educar 2014 -2017
                        <br /> Durante 2014, Heritage Group vendió 350 Mil tabletas a
                        Computadores para Educar, una entidad gubernamental dedicada a promover el uso de tecnologías para
                        la educación, siendo  éste el negocio  en tecnología más grande en Colombia.
                    </p>
                    <p className="lead">
                        Para el año 2016 fue
                        adjudicada  con el contrato de compraventa de 60 mil tabletas.
                    </p>

                </div>
                <div className="col-md-5">
                    <img src={cpe} alt="cpe" className="img-fluid mx-auto" /* width="500" height="400" */ />
                </div>
            </div>

            <hr className="featurette-divider" />

            <div className="row align-items-center featurette">
                <div className="col-md-7 order-md-2">
                    <h2 className="featurette-heading fw-normal lh-1">Pyme of the year <br /><span className="text-body-secondary"> Bancolombia 2017</span></h2>

                </div>
                <div className="col-md-5 order-md-1">
                    <video
                        width="500"/*  height="310" */
                        className="img-fluid mx-auto"
                        src={bancomp4}
                        type="video/mp4"
                        controls>
                    </video>
                </div>
            </div>

            <hr className="featurette-divider" />
        </>
    )
}

export default About
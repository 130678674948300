import React from 'react'
import imagen1 from '../assets/img/1.jpg'
import imagen2 from '../assets/img/2.jpg'
import imagen3 from '../assets/img/3.jpg'
import imagen4 from '../assets/img/4.jpg'

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../css/carrousel2.css'

const Carrousel = () => {


    const slides = [
        {
            image: imagen1,
            title: 'Heritage Group',
            subtitle: 'Somos fabricantes de equipos de cómputo y mayoristas de tecnología',
            btnText: 'Conoce nuestros productos',
            textAlign: 'text-start',
        },
        {
            image: imagen2,
            title: 'Empodera tu productividad con la revolución tecnológica.',
            subtitle: 'Descubre la innovación en cada detalle de nuestros equipos de última generación, diseñados para inspirar el rendimiento que tu empresa merece.',
        },
        {
            image: imagen3,
            title: 'El futuro de la informática está aquí, y lo creamos para ti',
            subtitle: 'Sumérgete en la experiencia de la tecnología de punta con nuestros equipos vanguardistas, fusionando diseño y rendimiento para llevar tu negocio a nuevas alturas.',
            textAlign: 'text-end',
        },
        {
            image: imagen4,
            title: 'No somos simplemente equipos, somos soluciones que transforman',
            subtitle: 'Descubre la diferencia con nuestra gama exclusiva de computadoras, donde la elegancia se encuentra con la potencia, creando una experiencia informática sin igual.',
            textAlign: 'text-end',
        },
    ];

    const settings = {
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000,
    };

    return (
        <>
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <div key={index} className="carousel-item mb-3">
                        <img src={slide.image} alt={`Slide ${index + 1}`} className="carousel-img" />
                        <div className="carousel-caption">
                            <h2>{slide.title}</h2>
                            <p>{slide.subtitle}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </>
    )
}

export default Carrousel
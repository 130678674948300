import React, { useEffect, useState } from 'react';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { obtenerURLImagen } from '../config/firebase';
import '../css/articulo.css';
import { LoaderES } from './LoaderES';

import accesorios from '../assets/img/acceorios.png';
import board from '../assets/img/board.png';
import brand from '../assets/img/brand.png';
import kase from '../assets/img/case.png';
import dd from '../assets/img/dd.png';
import fuente from '../assets/img/fuente.png';
import garan from '../assets/img/garantia.png';
import garanext from '../assets/img/garantiaext.png';
import monitor from '../assets/img/monitor.png';
import os from '../assets/img/os.png';
import precio from '../assets/img/precio.png';
import procesador from '../assets/img/proc.png';
import ram from '../assets/img/ram.png';

import audio from '../assets/img/audio.png';
import filtro from '../assets/img/filtro.png';
import lateral from '../assets/img/lateral.png';
import observaciones from '../assets/img/observacion.png';
import usb from '../assets/img/usb.png';
import ventilador from '../assets/img/ventilador.png';

import angulo from '../assets/img/angulo.png';
import conexion from '../assets/img/conexion.png';
import resolucion from '../assets/img/resolution.png';
import resoluciontype from '../assets/img/resolutiontype.png';
import tamano from '../assets/img/tamaño.png';


export const ArticuloModal = ({ articulo }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [listaImg, setListaImg] = useState(null)

  useEffect(() => {
    if (articulo?.listaImg) {
      cargarImagenes()
    }
  }, [articulo])

  const cargarImagenes = async () => {
    try {
      setIsLoading(true)
      const arreglo = [...articulo.listaImg];
      const temporal = [];

      for (const l of arreglo) {
        const url = await getImage(l);
        const tempo = { original: url, thumbnail: url };
        temporal.push(tempo);
      }

      setListaImg(temporal);
    } catch (error) {
      console.error("Error al cargar imágenes:", error);
    } finally {
      setIsLoading(false)
    }
  };

  const getImage = async (u) => {
    const response = await obtenerURLImagen(u)
    return response
  }

  const fila = (imagen, texto) => {
    if (texto !== '-' && texto !== '' && texto) {
      return (
        <tr>
          <td width={70}><img src={imagen} height={40} /></td>
          <td>{texto}</td>
        </tr>
      )
    }
  }

  return (
    <>
      <div className="modal-body">
        {isLoading || !listaImg
          ? <LoaderES />
          : <>
            <div className="container">
              <div className="row align-items-center ">
                <h5 className='text-center mb-5'>{articulo.ref}</h5>
              </div>
              <div className="row align-items-center justify-content-center">
                <div className="col-auto">
                  <div className="wrap d-md-flex">
                    <ImageGallery
                      items={listaImg}
                      lazyLoad={true}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      autoPlay={true}
                      showNav={false}
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <div className="wrap d-md-flex">
                    <div className="login-wrap ps-4">

                      <table className="table table-sm align-middle">
                        <tbody>

                          {fila(brand, articulo.marca)}

                          {fila(lateral, articulo.estructuraLateral)}
                          {fila(kase, articulo.estructuraFrontal)}
                          {fila(audio, articulo.audio)}
                          {fila(filtro, articulo.filtro)}
                          {fila(usb, articulo.usb)}
                          {fila(ventilador, articulo.ventiladores)}

                          {fila(procesador, articulo.procesador)}
                          {fila(board, articulo.board)}
                          {fila(dd, articulo.disco)}
                          {fila(ram, articulo.memoria)}
                          {fila(monitor, articulo.monitor)}
                          {fila(kase, articulo.kase)}
                          {fila(accesorios, articulo.accesorios)}
                          {fila(fuente, articulo.fuente)}
                          {fila(garan, articulo.garantiaNormal)}
                          {fila(garanext, articulo.garantiaExtendida)}
                          {fila(os, articulo.sistema)}

                          {fila(tamano, articulo.tamanoImg)}
                          {fila(resolucion, articulo.resolucionPantalla)}
                          {fila(angulo, articulo.anguloVision)}
                          {fila(conexion, articulo.tipoConexion)}
                          {fila(resoluciontype, articulo.tipoResolucion)}

                          {fila(observaciones, articulo.nombre)}
                          {fila(observaciones, articulo.descripcion)}

                          {fila(observaciones, articulo.observaciones)}
                          {fila(precio, '$ ' + (Intl.NumberFormat("es-CO").format(articulo.valor)))}
                         
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-3">
                <a className='btn  btn-primary text-center' target='_blank'
                  href={`https://wa.me/+573504825308?text=Me%20interesa%20este%20artículo:%0ACategoría:%20${articulo.categoria},%0AReferencia:%20${articulo.ref},%0AMe%20puedes%20dar%20mas%20información`}>
                  Info
                </a>
              </div>
            </div>
          </>
        }
      </div >
    </>
  )
}

import React from 'react'
import { Blocks } from "react-loader-spinner";

export const LoaderES = () => {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center text-center">
        <div className="col-auto text-center">
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { MenuItem, Menu as ProMenu, Sidebar, SubMenu } from 'react-pro-sidebar';
import { useHistory } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Accesorios } from './Accesorios';
import { Cases } from './Cases';
import { Consecutivos } from './Consecutivos';
import { LoaderES } from './LoaderES';
import { Marcas } from './Marcas';
import { Monitores } from './Monitores';
import { Pc } from './Pc';
import { Software } from './Software';
import { Usuarios } from './Usuarios';

import moment from 'moment';
import logow from '../assets/img/logo.png';
import logo from '../assets/img/logo2.png';
import LogoutButton from './LogoutButton';

import 'react-toastify/dist/ReactToastify.css';
import { validarMobil } from '../config/functions';
import '../css/menu.css';

const Menu = () => {
    const user = JSON.parse(localStorage.getItem("token")).userName;

    // const fechaActual = new Date();
    // const fechaFormateada = fechaActual.toLocaleDateString("es-ES");
    const [reloj, setReloj] = useState(moment().format('LTS'))
    const history = useHistory();

    const [mobile, setMobile] = useState(true)
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [broken, setBroken] = useState(false);
    const [theme, setTheme] = useState('light')
    const [isLoading, setIsLoading] = useState(false)

    const [componente, setComponente] = useState(localStorage.getItem('componente') || 'articulos')
    // const [activoHover, setActivoHover] = useState(null);

    // const [claveActual, setClaveActual] = useState("");
    // const [clave1, setClave1] = useState("");
    // const [clave2, setClave2] = useState("");

    // const usuarioModificacion = user;
    // const fechaModificacion = fechaFormateada;

    //------------------------------------------
    const hexToRgba = (hex, alpha) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const themes = {
        light: {
            sidebar: {
                backgroundColor: '#ffffff',
                color: '#607489',
            },
            font: {
                color: 'black'
            }
        },
        dark: {
            sidebar: {
                backgroundColor: '#0b2948',
                color: '#8ba1b7',
                /*  color: 'white', */
            },
            font: {
                color: 'white'
            }
        },
    };

    useEffect(() => {
        localStorage.setItem('collapsed', false);
        localStorage.setItem('broken', false);
        validarMobil(setMobile)
        iniciarReloj()
    }, [])

    const iniciarReloj = () => {
        const interval = setInterval(() => {
            setReloj(moment().format('LTS'))
        }, 1000);
        return () => clearInterval(interval);
    }

    const changeTitle = async (tittle) => {
        document.title = tittle;
    };

    useEffect(() => {
        localStorage.setItem('componente', componente);

        switch (componente) {
            case 'monitores':
                history.replace('/monitores');
                changeTitle('Monitores')
                break;
            case 'cases':
                history.replace('/cases');
                changeTitle('Cases')
                break;
            case 'nuc':
                history.replace('/nuc');
                changeTitle('Nuc')
                break;
            case 'corporativos':
                history.replace('/corporativos');
                changeTitle('Corporativos')
                break;
            case 'computadores':
                history.replace('/computadores');
                changeTitle('Computadores')
                break;
            case 'accesorios':
                history.replace('/accesorios');
                changeTitle('Accesorios')
                break;
            case 'software':
                history.replace('/software');
                changeTitle('Software')
                break;
            case 'marcas':
                history.replace('/marcas');
                changeTitle('Marcas')
                break;
            case 'consecutivos':
                history.replace('/consecutivos');
                changeTitle('Consecutivos')
                break;
            case 'usuarios':
                history.replace('/usuarios');
                changeTitle('Usuarios')
                break;

            default:
                break;
        }

    }, [componente])
    //-------------------------------------------------------------------
    return (
        <>
            <ToastContainer position="bottom-right" />

            <div style={{ display: 'flex', minHeight: '100vh' }}>

                <Sidebar collapsed={collapsed}
                    toggled={toggled}
                    rtl={false}
                    breakPoint="md"
                    rootStyles={{ color: themes[theme].sidebar.color, zIndex: 2 }}
                    backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
                    onBackdropClick={() => setToggled(false)}
                    onBreakPoint={(broken) => {
                        setBroken(broken)
                        localStorage.setItem('broken', broken)
                    }} >
                    <div className={broken ? 'mt-5' : ''}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            overflowY: 'hiden',
                            maxHeight: broken ? 'calc(100vh - 48px)' : '100vh'
                        }}>

                        <div style={{ flex: 1, marginBottom: '32px' }} className={broken ? 'mt-2' : ''}>

                            {/* Logo Empresa // Usuario */}
                            <div className="container">
                                <img src={theme === 'light' ? logo : logow}
                                    className="img-fluid mx-auto"
                                    style={{ width: mobile ? '150px' : '' }}
                                    alt="Logo" />
                                <div>
                                    {/* User */}
                                    <div className="container mb-4"
                                        style={{
                                            marginTop: collapsed ?
                                                '20px' : '',
                                            marginLeft: collapsed ?
                                                '-10px' : ''
                                        }}>
                                        <p className="text-center "
                                            style={{ fontSize: "15px", cursor: "pointer" }}>
                                            Usuario:  <strong className='text-info'> {user.toUpperCase()}</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <hr className="mb-2 " style={{ marginTop: collapsed ? '40px' : '30px' }} />

                            <ProMenu >
                                <SubMenu
                                    label="Artículos"
                                    icon={<i className="fa-solid fa-shop" />}>

                                    <MenuItem
                                        icon={<i className="fa-solid fa-desktop" />}
                                        active={componente === "monitores"}
                                        onClick={() => setComponente('monitores')}
                                        className={'update-item ' + (componente === "monitores" ? "text-info" : "")}
                                    >
                                        Monitores
                                    </MenuItem>

                                    <hr className="m-0 bg-light" />

                                    <MenuItem
                                        icon={<i className="fa-solid fa-window-maximize" />}
                                        active={componente === "cases"}
                                        onClick={() => setComponente('cases')}
                                        className={'update-item ' + (componente === "cases" ? "text-info" : "")}
                                    >
                                        Cases
                                    </MenuItem>

                                    <hr className="m-0 bg-light" />

                                    <MenuItem
                                        icon={<i className="fa-solid fa-hard-drive" />}
                                        active={componente === "nuc"}
                                        onClick={() => setComponente('nuc')}
                                        className={'update-item ' + (componente === "nuc" ? "text-info" : "")}
                                    >
                                        NUC
                                    </MenuItem>

                                    <hr className="m-0 bg-light" />

                                    <MenuItem
                                        icon={<i className="fa-solid fa-building-circle-check" />}
                                        active={componente === "corporativos"}
                                        onClick={() => setComponente('corporativos')}
                                        className={'update-item ' + (componente === "corporativos" ? "text-info" : "")}
                                    >
                                        Corporativos
                                    </MenuItem>

                                    <hr className="m-0 bg-light" />

                                    <MenuItem
                                        icon={<i className="fa-solid fa-computer" />}
                                        active={componente === "computadores"}
                                        onClick={() => setComponente('computadores')}
                                        className={'update-item ' + (componente === "computadores" ? "text-info" : "")}
                                    >
                                        Computadores
                                    </MenuItem>

                                    <hr className="m-0 bg-light" />

                                    <MenuItem
                                        icon={<i className="fa-solid fa-briefcase" />}
                                        active={componente === "accesorios"}
                                        onClick={() => setComponente('accesorios')}
                                        className={'update-item ' + (componente === "accesorios" ? "text-info" : "")}
                                    >
                                        Accesorios
                                    </MenuItem>

                                    <hr className="m-0 bg-light" />

                                    <MenuItem
                                        icon={<i className="fa-solid fa-laptop-code" />}
                                        active={componente === "software"}
                                        onClick={() => setComponente('software')}
                                        className={'update-item ' + (componente === "software" ? "text-info" : "")}
                                    >
                                        Software
                                    </MenuItem>

                                </SubMenu>
                                <hr className="m-0 bg-light" />
                                <SubMenu
                                    label="Administración"
                                    icon={<i className="fa-solid fa-user-shield" />}
                                >
                                    <MenuItem
                                        icon={<i className="fa-solid fa-copyright" />}
                                        active={componente === "marcas"}
                                        onClick={() => setComponente('marcas')}
                                        className={'update-item ' + (componente === "marcas" ? "text-info" : "")}
                                    >
                                        Marcas
                                    </MenuItem>

                                    <hr className="m-0 bg-light" />

                                    <MenuItem
                                        icon={<i className="fa-solid fa-list-ol" />}
                                        active={componente === "consecutivos"}
                                        onClick={() => setComponente('consecutivos')}
                                        className={'update-item ' + (componente === "consecutivos" ? "text-info" : "")}
                                    >
                                        Consecutivos
                                    </MenuItem>

                                    <hr className="m-0 bg-light" />

                                    <MenuItem
                                        icon={<i className="fa-solid fa-users-gear" />}
                                        active={componente === "usuarios"}
                                        onClick={() => setComponente('usuarios')}
                                        className={'update-item ' + (componente === "usuarios" ? "text-info" : "")}
                                    >
                                        Usuarios
                                    </MenuItem>

                                    <hr className="m-0 bg-light" />
                                </SubMenu>

                                {/* LogOut */}
                                <MenuItem icon={<i className="fa-solid fa-arrow-right-from-bracket" />}>
                                    <LogoutButton theme={theme} />
                                </MenuItem>

                            </ProMenu>

                        </div>
                        <button className={"btn border-0"}
                            onClick={() => {
                                setCollapsed(!collapsed)
                                localStorage.setItem('collapsed', !collapsed);
                            }}>
                            <i className={"fa-solid text-end " + (collapsed ? 'fa-angle-right ' : 'fa-angle-left ') + (theme === 'dark' ? 'text-white' : '')} />
                        </button>
                    </div>
                </Sidebar >

                <main className="w-100">

                    {/* Header */}
                    <nav className="navbar navbar-expand-lg fixed-top p-0 border-bottom"
                        style={{
                            backgroundColor: hexToRgba(themes[theme].sidebar.backgroundColor, 1),
                            marginLeft: broken || mobile ? '' : (!collapsed ? '250px' : '80px'),
                            transition: 'margin-left 0.3s ease'
                        }}>
                        <div className="container-fluid ">

                            <div className="col">
                                {broken ? (
                                    <button
                                        className={'btn' + (theme === 'dark' ? 'text-white' : '')}
                                        onClick={() => setToggled(!toggled)}>
                                        <i className="fa-solid fa-bars " />
                                    </button>
                                )
                                    : (
                                        <button className="btn border-0 "
                                            onClick={() => {
                                                setCollapsed(!collapsed)
                                                localStorage.setItem('collapsed', !collapsed);
                                            }}>
                                            <i className={"fa-solid text-end " + (collapsed ? 'fa-angle-right ' : 'fa-angle-left ') + (theme === 'dark' ? 'text-white' : '')} />
                                        </button>
                                    )}
                            </div>

                            <div className="col-auto">

                                <span className={"navbar-text fs-6 me-3 " + (theme === 'dark' ? 'text-white' : 'easystock-color')} >
                                    {reloj}
                                </span>

                                <span className={"navbar-text fs-6 me-1 " + (theme === 'dark' ? 'text-white' : 'easystock-color')} >
                                    {componente ? componente?.toUpperCase() : 'Home'}
                                </span>
                                <button className={'btn border-0 ' + (theme === 'dark' ? 'fa-rotate-180 text-white' : '')}
                                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
                                    <i className="fa-solid fa-circle-half-stroke" />
                                </button>

                            </div>
                        </div>
                    </nav>
                    {/* Header */}

                    <div className="container-fluid text-center mt-5 pt-1">
                        {isLoading
                            ? <LoaderES />
                            : <>
                                {componente === 'monitores' ? <Monitores /> : null}
                                {componente === 'cases' ? <Cases /> : null}
                                {componente === 'nuc' ? <Pc cat={"NUC"} cn={"NUC"} /> : null}
                                {componente === 'corporativos' ? <Pc cat={"CORPORATIVOS"} cn={"CRP"} /> : null}
                                {componente === 'computadores' ? <Pc cat={"COMPUTADORES"} cn={"CMP"} /> : null}
                                {componente === 'accesorios' ? <Accesorios /> : null}
                                {componente === 'software' ? <Software /> : null}
                                {componente === 'marcas' ? <Marcas /> : null}
                                {componente === 'consecutivos' ? <Consecutivos /> : null}
                                {componente === 'usuarios' ? <Usuarios /> : null}
                            </>
                        }
                    </div>
                </main>

            </div>



        </>
    )
}

export default Menu
import React, { useEffect, useState } from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo2 from '../assets/img/logo2.png';
import About from './About';
import Carrousel from './Carrousel';
import Footer from './Footer';
import NavBar from './NavBar';

import { consultarDocumentoDatabaseObject, obtenerURLImagen } from '../config/firebase';
import { fixConsecutivo, validarMobil } from '../config/functions';
import { Galeria } from './Galeria';
import { LoaderES } from './LoaderES';

import sortBy from 'sort-by';

export const Home = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [listaArt, setListaArt] = useState([]);
  const [mobile, setMobile] = useState(true);
  const [catActual, setCatActual] = useState("Computadores");

  const botones = [
    { clas: "nav-link active", id: "v-pills-comp-tab", target: "#comp", controls: "v-pills-comp", select: "true", tit: "Computadores" },
    { clas: "nav-link", id: "v-pills-nuc-tab", target: "#nuc", controls: "v-pills-nuc", select: "false", tit: "Intel NUC" },
    { clas: "nav-link", id: "v-pills-corp-tab", target: "#corp", controls: "v-pills-corp", select: "false", tit: "Corporativos" },
    { clas: "nav-link", id: "v-pills-acc-tab", target: "#acc", controls: "v-pills-acc", select: "false", tit: "Accesorios" },
    { clas: "nav-link", id: "v-pills-mon-tab", target: "#mon", controls: "v-pills-mon", select: "false", tit: "Monitores" },
    { clas: "nav-link", id: "v-pills-soft-tab", target: "#soft", controls: "v-pills-soft", select: "false", tit: "Software" },
    { clas: "nav-link", id: "v-pills-case-tab", target: "#case", controls: "v-pills-case", select: "false", tit: "Cases" },
  ]

  const categComponent = [
    { cls: "tab-pane fade show active", id: "comp", aria: "v-pills-comp-tab", component: <Galeria lc={listaArt} categoria={'COMPUTADORES'} /> },
    { cls: "tab-pane fade ", id: "corp", aria: "v-pills-corp-tab", component: <Galeria lc={listaArt} categoria={'CORPORATIVOS'} /> },
    { cls: "tab-pane fade ", id: "acc", aria: "v-pills-acc-tab", component: <Galeria lc={listaArt} categoria={'ACCESORIOS'} /> },
    { cls: "tab-pane fade ", id: "mon", aria: "v-pills-mon-tab", component: <Galeria lc={listaArt} categoria={'MONITORES'} /> },
    { cls: "tab-pane fade ", id: "soft", aria: "v-pills-soft-tab", component: <Galeria lc={listaArt} categoria={'SOFTWARE'} /> },
    { cls: "tab-pane fade ", id: "case", aria: "v-pills-case-tab", component: <Galeria lc={listaArt} categoria={'CASE'} /> },
    { cls: "tab-pane fade ", id: "nuc", aria: "v-pills-nuc-tab", component: <Galeria lc={listaArt} categoria={'NUC'} /> },
  ]

  const cargarArt = async () => {
    setIsLoading(true)
    try {
      const listaTemporal = await consultarDocumentoDatabaseObject("articulos", "articulos");

      if (listaTemporal.length > 0) {
        const consecOrden = [...listaTemporal];
        await Promise.all(
          consecOrden.map(async (p) => {
            p.consecutivoOk = fixConsecutivo(p.consecutivo);
            p.urlOk = await getImage(p.listaImg[0]);
          })
        );
        setListaArt(consecOrden.sort(sortBy('consecutivoOk')));
      };
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    cargarArt()
    validarMobil(setMobile)
  }, [])

  const getImage = async (u) => {
    try {
      const response = await obtenerURLImagen(u)
      if (response) {
        return response
      } else {
        return ('')
      }
    } catch (error) {
      console.log("Error: ", error)
    }
  }



  return (
    <>
      <FloatingWhatsApp
        phoneNumber="57 3504825308"
        accountName="Heritage Group"
        allowEsc
        allowClickAway
        notification
        notificationSound
        //statusMessage='Control-Manage-Expand'
        chatMessage={`Tienes alguna pregunta sobre algun artículo de la categoría ${catActual} ?`}
        buttonClassName="me-4"
        buttonStyle={{ marginBottom: '80px' }}
        chatboxStyle={{ marginBottom: '70px' }}
        avatar={logo2}
      />
      <NavBar />

      <Carrousel />
      <div className="container" id='productos'>

        <hr className="featurette-divider" />

        <ul className="nav nav-pills nav-fill mb-3">
          {botones.map((b, index) => (
            <li className="nav-item">
              <button
                key={index}
                className={b.clas}
                id={b.id}
                data-bs-toggle="pill"
                data-bs-target={b.target}
                type="button"
                role="tab"
                aria-controls={b.controls}
                aria-selected={b.select}
                onClick={() => setCatActual(b.tit)} >
                {b.tit}
              </button>
            </li>
          ))}
        </ul>

        <div className="d-flex align-items-center">

          {isLoading ?
            <LoaderES />
            :
            <div className="tab-content w-100" id="v-pills-tabContent">

              {categComponent.map(c => (
                <div className={c.cls}
                  id={c.id}
                  role="tabpanel"
                  aria-labelledby={c.aria}
                  tabIndex="0"
                  key={c.id}>
                  {c.component}
                </div>
              ))}
            </div>
          }

        </div>

        <About />
      </div >



      <Footer />
    </>
  )
}

import { toast } from "react-toastify";
import sortBy from "sort-by";
import { actualizarDocumentoDatabase, consultarDatabase, consultarDocumentoWhere } from "./firebase";

const user = JSON.parse(localStorage.getItem("token"))?.userName ? JSON.parse(localStorage.getItem("token")).userName : '';
const fechaActual = new Date();
const fechaFormateada = fechaActual.toLocaleDateString("es-CO");
const fechaModificacion = fechaFormateada


export const fixConsecutivo = (c) => {
    const a = c.split('-')[0]
    const b = c.split('-')[1]
    const d = b.length
    const e = (d === 1 ? ('0' + b) : b)
    return (a + '-' + e)
}

export const validarMobil = (setMobile) => {
    const handleResize = () => {
        if (window.innerWidth < 768) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}

// ------------Columnas------------------
export const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + '/' + tabla + '/' + columna, JSON.stringify(valor));
}

export const buscarColumnas = (columnas, tabla, setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8, setCol9, setCol11, setCol12, setCol13, setCol14, setCol15, setCol16, setCol17, setCol18) => {

    columnas.forEach((columna) => {

        const valor = localStorage.getItem(`${user}/${tabla}/${columna}`);
        const estado = valor === 'false' ? false : true;
        switch (columna) {
            case 2:
                setCol2(estado);
                break;
            case 3:
                setCol3(estado);
                break;
            case 4:
                setCol4(estado);
                break;
            case 5:
                setCol5(estado);
                break;
            case 6:
                setCol6(estado);
                break;
            case 7:
                setCol7(estado);
                break;
            case 8:
                setCol8(estado);
                break;
            case 9:
                setCol9(estado);
                break;
            case 11:
                setCol11(estado);
                break;
            case 12:
                setCol12(estado);
                break;
            case 13:
                setCol13(estado);
                break;
            case 14:
                setCol14(estado);
                break;
            case 15:
                setCol15(estado);
                break;
            case 16:
                setCol16(estado);
                break;
            case 17:
                setCol17(estado);
                break;
            case 18:
                setCol18(estado);
                break;
            default:
                break;
        }
    });


}

export const ordenar = (columna, countOrdenar, documentosFiltrados, setListaConsecutivos, setCountOrdenar) => {

    const direccion = (countOrdenar % 2 === 0 ? '' : '-')
    const temporal = documentosFiltrados.sort(sortBy(direccion + columna))

    setListaConsecutivos(temporal);
    setCountOrdenar(countOrdenar + 1)
};

// ---------------Imagenes-----------
export const limpiar = (setUpdateImg, setDeleteImg, setAddImg, setCantImg) => {
    setUpdateImg(false)
    setDeleteImg(false)
    setAddImg(false)
    setCantImg(0)
}

export const clearInputFile = (inputFile) => {
    if (inputFile && inputFile.current && (inputFile.current.value !== "" || inputFile.current.value === null || inputFile.current.value === "")) {
        inputFile.current.value = '';
    }
};

// ------------Marcas----------------
export const cargarMarcas = async () => {

    const lista = await consultarDatabase("marcas");

    if (lista.length > 0) {
        const activos = lista.filter((c) => c.estado === "ACTIVO");
        if (activos.length > 0) {
            return { lstAct: activos, lstCons: lista };
        } else {
            return { lstAct: activos, lstCons: lista };
        }
    } else {
        return { lstCons: lista };
    }
};

//--------Paginacion------------
export const calcularTotalPaginas = (documentosFiltrados, registrosPorPagina) => {
    return Math.ceil(documentosFiltrados.length / registrosPorPagina);
}

export const calcularRegistrosPagina = (documentosFiltrados, paginaActual, registrosPorPagina) => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina);
    const fin = inicio + parseInt(registrosPorPagina);
    return documentosFiltrados.slice(inicio, fin);
}

//--------- Mobile collapse -----------
export const crearArregloInicial = (tamaño) => Array(tamaño).fill(false);

export const toggleOpen = (i, isOpen, setIsOpen) => {
    const temporal = [...isOpen]
    temporal[i] = !temporal[i]
    setIsOpen(temporal);
};


//---------- Consecutivos ----------
export const cargarConsecutivos = async (prefijo, setConsecOk, setListaConsec, categoria) => {

    const listTempMnt = await consultarDocumentoWhere("consecutivos", "prefijo", prefijo);

    if (listTempMnt.length > 0) {
        const activos = listTempMnt.filter((c) => c.estado === "ACTIVO")

        if (activos.length > 0) {
            setConsecOk(true)
            setListaConsec(activos);
        } else {
            setConsecOk(false)
            toast.warning(`El consecutivo de ${categoria} no esta activo, por favor verifique.`)
        }
    } else {
        setConsecOk(false)
        toast.warning(`No hay consecutivo para la creacion de ${categoria}, por favor crearlo.`)
    }
};

export const manipularConsecutivos = (lista, setIdDoc, setVerConsec, setConsecutivo) => {
    const valorCon = lista[0]?.valorActual;
    const prefCom = lista[0]?.prefijo;
    const docCom = lista[0]?.idDocumento;
    const valCom = parseInt(valorCon + 1);
    setIdDoc(docCom);
    setConsecutivo(valCom);
    setVerConsec(prefCom + "-" + valCom);

};

export const modificarConsecutivos = async (valor, idDoc, listaConsec, setIdDocConsec, setVerConsec, prefijo, setConsecOk, setListaConsec, setConsecutivo,categoria) => {

    const ListaModificacion = {
        valorActual: parseInt(valor.split("-")[1]),
        fechaModificacion: fechaModificacion,
        usuarioModificacion: user.toUpperCase(),
    };
    actualizarDocumentoDatabase("consecutivos", idDoc, ListaModificacion);
    await cargarConsecutivos(prefijo, setConsecOk, setListaConsec,categoria);
    manipularConsecutivos(listaConsec, setIdDocConsec, setVerConsec, setConsecutivo);
}


// ---------------Imagenes-----------

// export const handleImageSelect = (event, tipo, setImgNew, complemento, consecuti, setListImgSelec, setListUrlImg, setRutaEnFirestore, imgActualizar, verConsec) => {
//     if (tipo === 1) {
//         const image = event.target.files[0];
//         if (image) {
//             setImgNew(image);
//             let url = `${rutaArticulos}${complemento}/${consecuti}/img/${image.name}`;
//             setRutaEnFirestore(url)
//             setListUrlImg([])
//         } else {
//             toast.warning('Debe seleccionar una imágen para guardar, intente nuevamente')
//         }

//     } else if (tipo === 2) {

//         let lsImg = []
//         const images = event.target.files;
//         setListImgSelec(images)

//         for (let i = 0; i < images.length; i++) {
//             let listImgSelec = images[i];
//             if (listImgSelec) {
//                 let url = `${rutaArticulos}/${verConsec}/img/${listImgSelec.name}`;
//                 lsImg = [...lsImg, url]
//             }
//         }

//         if (lsImg.length > 5) {
//             toast.warning("El maximo de imágenes permitida es de 5, verifica e intenta de nuevo")
//             lsImg = []
//             return (true)
//         } else {
//             setListUrlImg(lsImg)
//         }

//     } else if (tipo === 3) {
//         const image = event.target.files[0];
//         if (image) {
//             setImgNew(image);
//             setRutaEnFirestore(imgActualizar.url)
//         } else {
//             toast.warning('Debe seleccionar una imágen para guardar, intente nuevamente')
//         }
//     }
// };

// export const handleSubirImagenes = async (tipo, imgNew, rutaEnFirestore, setImgNew, listUrlImg, listImgSelec, setListUrlImg, setListImgSelec) => {
//     if (tipo === 1) {
//         if (imgNew && rutaEnFirestore) {
//             await subirImagen(rutaEnFirestore, imgNew);
//             toast.success("La imágen fue cargada correctamente")
//             setImgNew('')
//         }
//     } else if (tipo === 2) {
//         if (listUrlImg.length !== listImgSelec.length) {
//             console.error('Las listas no tienen la misma longitud');
//             return;
//         }
//         let images = listUrlImg.map((url, index) => ({ url, img: listImgSelec[index] }));
//         for (let image of images) {
//             if (image.img && image.url) {
//                 await subirImagen(image.url, image.img);
//             }
//         }
//         setListUrlImg([]);
//         setListImgSelec([]);
//         toast.success("Las imágenes fueron cargadas correctamente");
//     }
// };

// export const handleEliminarImagen = async (tipo, imgBorrar, resetStates, imagenURLS) => {
//     if (tipo === 1) {
//         if (imgBorrar) {
//             await eliminarImagen(imgBorrar.url);
//             toast.success('La imagen fue eliminada correctamente')
//             resetStates()
//         }
//     } else if (tipo === 2) {
//         if (imagenURLS.length > 0) {
//             for (let urlImg of imagenURLS) {
//                 if (urlImg) {
//                     await eliminarImagen(urlImg.url);
//                 }
//             }
//         }
//     }
// };

// export const handleActualizarImagen = async (imgNew, rutaEnFirestore, setImgNew, resetStates) => {
//     if (imgNew && rutaEnFirestore) {
//         await actualizarImagen(rutaEnFirestore, imgNew);
//         toast.success('La imágen fue actualizada correctamente')
//         setImgNew("")
//         resetStates()
//     } else {
//         toast.warning('Debe seleccionar una imágen para modificar, intente nuevamente')
//         setImgNew("")
//     }
// };

// export const handleObtenerURLImagen = async (rutas, setImagenURLS, setCantImg) => {
//     let im = []
//     if (rutas.length > 0) {
//         for (let ruta of rutas) {
//             if (ruta) {
//                 let r = await obtenerURLImagen(ruta);
//                 let obj = { ruta: ruta, url: r };
//                 im = [...im, obj]
//             }
//         }
//         setImagenURLS(im)
//         setCantImg(im.length)
//     } else {
//         toast.warning('El artículo no tiene imágenes disponibles, debe cargarlas en este modal')
//         setCantImg(0)
//     }
// };
//---------------------------------
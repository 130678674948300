import React, { useEffect, useState } from 'react';

export const Filtrar = ({ documentos, setRegistrosFiltrados, registrosPorPagina, setDocumentosFiltrados }) => {
  
  const [filtro, setFiltro] = useState('');
  
  useEffect(() => {
    const filtrarRegistros = (data, filtro) => {
      const registrosFiltrados = data.filter((registro) =>
        filterRecursive(registro, filtro)
      );
      setDocumentosFiltrados(registrosFiltrados)
      setRegistrosFiltrados(registrosFiltrados.slice(0, registrosPorPagina));
    };

    const filterRecursive = (data, filtro) => {
      if (Array.isArray(data)) {
        return data?.some((item) => filterRecursive(item, filtro));
      } else if (typeof data === 'object' && data !== null) {
        return Object.values(data)
          .some((value) => filterRecursive(value, filtro));
      } else if (typeof data === 'string') {
        return data?.toUpperCase().includes(filtro?.toUpperCase());
      }
      return false;
    };

    filtrarRegistros(documentos, filtro);

  }, [filtro, documentos, setRegistrosFiltrados, setDocumentosFiltrados]);

  return (
    <div className="input-group input-group-sm data-filter">
      <span className="input-group-text">&#x1F50E;&#xFE0E;</span>
      <input
        type="text"
        className="form-control"
        placeholder="Buscar"
        value={filtro}
        onChange={(e) => setFiltro(e.target.value)}
      />
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import sortBy from "sort-by";
import uniqid from "uniqid";
import { Filtrar } from "./Filtrar";
import { LoaderES } from "./LoaderES";
import ModalCoumnas from "./ModalColumnas";
import { Pagination } from "./Pagination";

import {
    actualizarDocumentoDatabase,
    consultarDatabase,
    eliminarDocumentoDatabase,
    guardarDatabase
} from "../config/firebase";

import {
    buscarColumnas,
    calcularRegistrosPagina,
    calcularTotalPaginas,
    cargarConsecutivos,
    crearArregloInicial,
    fixConsecutivo,
    manipularConsecutivos,
    modificarConsecutivos,
    ordenar,
    toggleOpen,
    validarMobil,
} from './../config/functions';

export const Marcas = () => {
    const user = JSON.parse(localStorage.getItem("token")).userName;

    const broken = JSON.parse(localStorage.getItem("broken"));
    const collapsed = JSON.parse(localStorage.getItem("collapsed"));

    const fechaActual = new Date();
    const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

    // Paginacion
    const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
    const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
    const [paginaActual, setPaginaActual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(0)
    const [registrosPaginaActual, setRegistrosPaginaActual] = useState([])

    // General
    const [isOpen, setIsOpen] = useState(null);
    const [add, setAdd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [mobile, setMobile] = useState(true);
    const [consecOk, setConsecOk] = useState(false);

    // Marcas
    const [listaMarcas, setListaMarcas] = useState([]);
    const [marca, setMarca] = useState("")
    const [estado, setEstado] = useState("")
    const [consecutivoEliminar, setConsecutivoEliminar] = useState("");
    const [idDocumento, setIdDocumento] = useState("");

    // Cosecutivos
    const [listaConsecMar, setListaConsecMar] = useState([]);
    const [verConsecMar, setVerConsecMar] = useState("");
    const [idDocConsecMar, setIdDocConsecMar] = useState("");
    const [consecutivo, setConsecutivo] = useState("");

    // Usuario
    const usuarioCreacion = user;
    const fechaCreacion = fechaFormateada;
    const usuarioModificacion = user;
    const fechaModificacion = fechaFormateada;

    // Columnas
    const [col2, setCol2] = useState(true)
    const [col3, setCol3] = useState(true)
    const [countOrdenar, setCountOrdenar] = useState(0)


    /*************** Funciones ***********************/
    useEffect(() => {
        cargarMarcas()
        cargarConsecutivos("MRC", setConsecOk, setListaConsecMar, "MARCA")
        buscarColumnas([2, 3], 'marca', setCol2, setCol3)
        validarMobil(setMobile)
    }, [])

    useEffect(() => {
        if (add) {
            resetStates();
        }
    }, [add]);

    useEffect(() => {
        setIsOpen(crearArregloInicial(registrosPaginaActual.length))
    }, [registrosPaginaActual])

    const cargarMarcas = async () => {
        setIsLoading(true)
        try {
            const listaTemporal = await consultarDatabase("marcas");

            if (listaTemporal.length > 0) {
                const consecOrden = [...listaTemporal]
                consecOrden.forEach(p => {
                    p.consecutivoOk = fixConsecutivo(p.consecutivo)
                });
                setListaMarcas(consecOrden.sort(sortBy('consecutivoOk')));
                setDocumentosFiltrados(consecOrden.sort(sortBy('consecutivoOk')));
            } else {
                const temporal = [{
                    consecutivo: '',
                    marca: '',
                    estado: '',
                }]
                setListaMarcas(temporal);
                setDocumentosFiltrados(temporal)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    };

    const handleClickAdicionar = async () => {

        const respuesta = listaMarcas.find(m => m.marca === marca);

        if (respuesta) {
            toast.info("La marca : " + marca + " ya se encuentra registrada, intente de nuevo");
            resetStates();
        } else {

            const enviarArticulo = {
                id: uniqid("marca-"),
                consecutivo: consecutivo.toUpperCase(),
                marca: marca.toUpperCase(),
                estado: estado.toUpperCase(),
                fechaCreacion: fechaCreacion,
                usuarioCreacion: usuarioCreacion.toUpperCase(),
            };
            if (consecutivo === "" || marca === "" || estado === "") {
                toast.warning("Diligencie todos los campos de manera correcta");
            } else {
                await guardarDatabase("marcas", enviarArticulo);
                setVerConsecMar("");
                await modificarConsecutivos(verConsecMar, idDocConsecMar, listaConsecMar, setIdDocConsecMar, setVerConsecMar, "MRC", setConsecOk, setListaConsecMar, setConsecutivo, "MARCA");
                await cargarMarcas();
                toast.success("Marca agregada de manera correcta");
                resetStates();
            }
        }
    };

    const handleClickTraerMarca = (m) => {
        setIdDocumento(m.idDocumento)
        setConsecutivo(m.consecutivo)
        setMarca(m.marca)
        setEstado(m.estado)
    };

    const handleClickModificarBd = async () => {

        const enviarArticulo = {
            marca: marca.toUpperCase(),
            estado: estado.toUpperCase(),
            fechaModificacion: fechaModificacion,
            usuarioModificacion: usuarioModificacion.toUpperCase(),
        };
        if (idDocumento !== "" && idDocumento !== undefined) {
            setIsLoading(true);
            try {
                await actualizarDocumentoDatabase("marcas", idDocumento, enviarArticulo);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
            toast.success("Marca Modificada correctamente");
            await cargarMarcas();
            resetStates();
        } else {
            toast.warning("Seleccione una marca valida para modificarla");
        }
    };

    const handleClickEliminar = async (a) => {
        if (a.idDocumento !== "" && a.idDocumento !== undefined) {
            setIsLoading(true);
            try {
                await eliminarDocumentoDatabase("marcas", a.idDocumento);
                toast.success("Marca eliminada correctamente");
                cargarMarcas()

            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        } else {
            toast.warning("Seleccione una marca para eliminar");
        }
    };

    const resetStates = () => {
        setConsecutivo("")
        setMarca("")
        setEstado("")
    };

    //---consecutivos-----------
    useEffect(() => {
        manipularConsecutivos(listaConsecMar, setIdDocConsecMar, setVerConsecMar, setConsecutivo);
    }, [listaConsecMar]);

    //---------Paginacion----------
    useEffect(() => {
        setTotalPaginas(calcularTotalPaginas(documentosFiltrados, registrosPorPagina));
    }, [documentosFiltrados, registrosPorPagina]);

    useEffect(() => {
        setRegistrosPaginaActual(calcularRegistrosPagina(documentosFiltrados, paginaActual, registrosPorPagina));
    }, [paginaActual, totalPaginas, countOrdenar]);



    return (
        <>
            {isLoading
                ? <LoaderES />
                : <>
                    <div className="fixed-top "
                        style={{
                            backgroundColor: 'white',
                            marginLeft: broken || mobile ? '10px' : (!collapsed ? '260px' : '90px'),
                            marginRight: broken || mobile ? '10px' : (!collapsed ? '15px' : '15px'),
                            transition: 'margin-left 0.3s ease',
                            top: '46px',
                            zIndex: 1
                        }}
                    >

                        {/* botones de opcion */}
                        <div className="row mb-2">
                            <div className="col-sm-4 align-self-start text-start">

                                {/* Adicionar */}
                                {verConsecMar !== "" ?
                                    <button
                                        className="btn btn-sm"
                                        data-bs-toggle="modal"
                                        data-bs-target="#ModAdiMarca"
                                        title="Crear Marca"
                                        onClick={() => setAdd(true)} >
                                        <i className="fa-solid fa-plus" />
                                    </button>
                                    : null}

                                {/* Columnas */}
                                <button
                                    className="btn btn-sm"
                                    title="Seleccionar Columnas"
                                    data-bs-toggle="modal"
                                    data-bs-target="#ModificarColumnas"
                                    id="modalcolumnas" >
                                    <i className="fa-solid fa-table-columns" />
                                </button>

                                {/* Actualizar */}
                                <button
                                    title="Actualizar Datos"
                                    className="btn btn-sm"
                                    onClick={() => cargarMarcas()}>
                                    <i className="fa-solid fa-rotate" />
                                </button>
                            </div>

                            {/* filtrar */}
                            <div className="col-sm-8">
                                <Filtrar
                                    tabla="myTable"
                                    documentos={listaMarcas}
                                    setDocumentosFiltrados={setDocumentosFiltrados}
                                    setRegistrosFiltrados={setRegistrosPaginaActual}
                                    registrosPorPagina={registrosPorPagina} />
                            </div>
                        </div>
                    </div>

                    <hr className={" mt-5 " + (mobile === true ? ' mb-5' : '')} />

                    {mobile
                        ?
                        /* Mobile */
                        <>
                            {registrosPaginaActual?.map((m, index) => (
                                <div key={m.consecutivo + m.id}>
                                    <table className="table table-sm table-hover table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '50px', resize: 'none' }}>
                                                    <button className="btn btn-sm"
                                                        type="button"
                                                        onClick={() => toggleOpen(index, isOpen, setIsOpen)}
                                                        data-bs-target={"#collapseItem" + m.consecutivo}
                                                        aria-expanded={isOpen[index]}
                                                        aria-controls="collapseItem" >
                                                        <i className={`fa-solid ${isOpen[index] ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                                                    </button>
                                                </th>
                                                <th style={{ width: '65px', resize: 'none' }}>{m.consecutivo}</th>
                                                <td style={{ minWidth: '60px', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', resize: 'none' }}>
                                                    <span className="truncate-text">
                                                        {m.marca.length > 50 ? m.marca.slice(0, 50) + '...' : m.marca}
                                                    </span>
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>


                                    <div className={`collapse card card-body ${isOpen[index] ? 'show' : ''}`} id={"collapseItem" + m.consecutivo}>
                                        <table className="table table-sm">
                                            <tbody >
                                                <tr>
                                                    <td></td>
                                                    <th style={{ resize: 'none' }} className="text-start">Consecutivo </th>
                                                    <td className="text-start">{m.consecutivo}</td>
                                                </tr>

                                                {!col2 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Marca </th>
                                                        <td className="text-start">{m.marca}</td>
                                                    </tr>
                                                }

                                                {!col3 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Estado </th>
                                                        <td className="text-start">{m.estado}</td>
                                                    </tr>
                                                }

                                                <tr>
                                                    <td></td>
                                                    <th style={{ resize: 'none' }} className="text-start">Actions </th>
                                                    <td className="text-start">
                                                        <div className="btn-group">
                                                            {/* Btn Modificar */}
                                                            <button
                                                                className="btn btn-sm btn-outline-info"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#ModAdiMarca"
                                                                title="Modificar Item Seleccionado"
                                                                onClick={() => {
                                                                    setAdd(false)
                                                                    handleClickTraerMarca(m)
                                                                }}>
                                                                <i className="fa-regular fa-pen-to-square" />
                                                            </button>

                                                            {/* Btn Eliminar */}
                                                            <button
                                                                className="btn btn-sm btn-outline-danger"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#EliminarMarca"
                                                                title="Eliminar Item Seleccionado"
                                                                onClick={() => setConsecutivoEliminar(m)} >
                                                                <i className="fa-regular fa-trash-can" />
                                                            </button>

                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            ))}
                        </>
                        /* Mobile */
                        :
                        /* Desktop */
                        <div className="table-responsive">
                            <table
                                id="myTable"
                                className="table table-hover table-striped table-sm table-bordered align-middle">
                                <thead className="text-center">
                                    <tr >
                                        <th onClick={() => ordenar('consecutivoOk', countOrdenar, documentosFiltrados, setListaConsecMar, setCountOrdenar)}>Consec.</th>
                                        <th onClick={() => ordenar('marca', countOrdenar, documentosFiltrados, setListaConsecMar, setCountOrdenar)} className={col2 ? '' : 'd-none'}>Marca</th>
                                        <th onClick={() => ordenar('estado', countOrdenar, documentosFiltrados, setListaConsecMar, setCountOrdenar)} className={col3 ? '' : 'd-none'}>Estado</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="text-center" id="tabla_articulos">

                                    {registrosPaginaActual.length === 1 && registrosPaginaActual[0].consecutivo === '' ? null

                                        : registrosPaginaActual?.map((m) => (
                                            <tr key={m.consecutivo + m.id}>
                                                <td>{m.consecutivoOk}</td>
                                                <td className={col2 ? '' : 'd-none'}>{m.marca}</td>
                                                <td className={col3 ? '' : 'd-none'}>{m.estado}</td>

                                                {/* Btns */}
                                                <td className="text-center">
                                                    <div className="btn-group">

                                                        {/* Btn Modificar */}
                                                        <button
                                                            className="btn btn-sm btn-outline-info"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#ModAdiMarca"
                                                            title="Modificar Item Seleccionado"
                                                            onClick={() => {
                                                                setAdd(false)
                                                                handleClickTraerMarca(m)
                                                            }}>
                                                            <i className="fa-regular fa-pen-to-square" />
                                                        </button>

                                                        {/* Btn Eliminar */}
                                                        <button
                                                            className="btn btn-sm btn-outline-danger"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#EliminarMarca"
                                                            title="Eliminar Item Seleccionado"
                                                            onClick={() => setConsecutivoEliminar(m)}>
                                                            <i className="fa-regular fa-trash-can" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        /* Desktop */
                    }
                    <Pagination
                        paginaActual={paginaActual}
                        totalPaginas={totalPaginas}
                        setPaginaActual={setPaginaActual}
                        registrosPorPagina={registrosPorPagina}
                        setRegistrosPorPagina={setRegistrosPorPagina}
                        documentosFiltrados={documentosFiltrados}
                    />

                    {/* + Modal de Modificar // Adicionar monitores*/}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="ModAdiMarca"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >

                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className={add ? "modal-header bg-primary text-light justify-content-center" : "modal-header bg-info text-secondary justify-content-center"}>
                                    <h5 className="modal-title fs-5">
                                        {add ? "Crear " : "Modificar "} Marca
                                    </h5>
                                </div>

                                {/* Formulario */}
                                <div className="modal-body">

                                    {/* Consecutivo */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Consecutivo:</span>
                                        {consecOk ?
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={add ? verConsecMar : consecutivo}
                                                disabled />
                                            :
                                            <input
                                                className="form-control text-danger"
                                                type="text"
                                                value={'Sin consecutivo dipsonible'}
                                                disabled />}
                                    </div>

                                    {/* Categoria  */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Marca:</span>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={marca}
                                            onChange={(e) => {
                                                setMarca(e.target.value.toUpperCase())
                                                setConsecutivo(verConsecMar)
                                            }} />
                                    </div>

                                    {/* Estado */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Estado: </span>
                                        <select
                                            className="form-select"
                                            value={estado}
                                            onChange={(e) => setEstado(e.target.value)}>
                                            <option value="" disabled>Seleccione</option>
                                            <option value="ACTIVO">Activo</option>
                                            <option value="INACTIVO">Inactivo</option>
                                        </select>
                                    </div>

                                </div>

                                <div className="modal-footer">
                                    {consecOk ?
                                        <button
                                            type="button"
                                            className={add ? "btn btn-sm btn-primary text-light" : "btn btn-sm btn-info text-secondary"}
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                add ?
                                                    handleClickAdicionar()
                                                    : handleClickModificarBd()
                                            }}>
                                            {add ? "Crear" : "Modificar"}
                                        </button>
                                        : null}

                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        data-bs-dismiss="modal"
                                        onClick={() => resetStates()}>
                                        Cancelar
                                         </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal de Modificar // Adicionar */}

                    {/* +Modal Eliminar */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="EliminarMarca"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >

                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className="modal-header bg-danger text-white justify-content-center">
                                    <h5 className="modal-title  " id="staticBackdropLabel3">
                                        Alerta Eliminar Marca
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <div className="modal-body text-center">
                                        <p>
                                            Está seguro de eliminar <br /> la marca: <strong> {' ' + consecutivoEliminar.marca}</strong>, <br />
                                            <span className="text-danger mt-2">
                                                Recuerde que esta acción no se puede revertir.
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        data-bs-dismiss="modal"
                                        id="btnEliminarArticulo"
                                        onClick={() => handleClickEliminar(consecutivoEliminar)} >
                                        Si, eliminar
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal" >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal Eliminar */}

                    {/* +Modal Columnas */}
                    <ModalCoumnas
                        columnas={['Marca', 'Estado']}
                        identificador={'marca'}
                        col2={col2} col3={col3}
                        setCol2={setCol2} setCol3={setCol3} />
                </>
            }
        </>
    )
}

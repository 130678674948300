import React from 'react';
import { agregarColumna } from '../config/functions';

const ModalCoumnas = ({ columnas, identificador,
    col2, col3, col4, col5, col6, col7, col8, col9, col11, col12, col13, col14, col15, col16, col17, col18,
    setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8, setCol9, setCol11, setCol12, setCol13, setCol14, setCol15, setCol16, setCol17, SetCol18
}) => {

    const elementoModal = (name, setCol, col, idenNumber) => {
        return (
            <div className="form-check" key={name}>
                <input className="form-check-input"
                    type="checkbox"
                    checked={col}
                    onChange={(e) => {
                        setCol(e.target.checked)
                        agregarColumna(identificador, idenNumber, e.target.checked)
                    }}
                    id={name} />
                <label className="form-check-label"
                    htmlFor={name}>
                    {name}
                </label>
            </div>
        )
    }

    const setColFunctions = [setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8, setCol9, setCol11, setCol12, setCol13, setCol14, setCol15, setCol16, setCol17, SetCol18];
    const colFunctions = [col2, col3, col4, col5, col6, col7, col8, col9, col11, col12, col13, col14, col15, col16, col17, col18]

    return (
        <div
            className="modal fade"
            aria-hidden="true"
            id="ModificarColumnas"
            tabIndex="-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false" >

            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                <div className="modal-content ">
                    <div className="modal-header bg-primary text-light justify-content-center">
                        <h5 className="modal-title" id="staticBackdropLabel3">
                            Selección de columnas</h5>
                    </div>

                    <div className="modal-body">
                        {columnas.map((c, index) => (
                            elementoModal(c, setColFunctions[index], colFunctions[index], index + 2)
                        ))}

                    </div>

                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal">
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCoumnas;
import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Articulo } from "../components/Articulo";
import { Home } from "../components/Home";
import Menu from "../components/Menu";
import { RutaPrivada } from "../components/RutaPrivada";

export const AppRouter = () => {
  return (
    <>
      <Router basename="/">
        <Switch>
          {/* RUTA PUBLICA */}
          <Route path="/" exact component={Home} />
          <Route path="/articulo" component={Articulo} />

          {/* RUTA PRIVADA */}
          <RutaPrivada path="/menu" component={Menu} />
          <RutaPrivada path="/monitores" component={Menu} />
          <RutaPrivada path="/cases" component={Menu} />
          <RutaPrivada path="/nuc" component={Menu} />
          <RutaPrivada path="/corporativos" component={Menu} />
          <RutaPrivada path="/computadores" component={Menu} />
          <RutaPrivada path="/accesorios" component={Menu} />
          <RutaPrivada path="/software" component={Menu} />
          <RutaPrivada path="/marcas" component={Menu} />
          <RutaPrivada path="/consecutivos" component={Menu} />
          <RutaPrivada path="/usuarios" component={Menu} />

          {/* <Route path="/articulos" component={Articulos} /> */}
          {/* <Route path="/prueba" component={Home2} /> */}
          {/* <RutaPrivada path="/home" component={Menu} /> */}

        </Switch>
      </Router>
    </>
  );
};

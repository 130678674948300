
import bcrypt from 'bcryptjs';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import sortBy from "sort-by";
import uniqid from "uniqid";
import { Filtrar } from "./Filtrar";
import { LoaderES } from './LoaderES';
import ModalCoumnas from "./ModalColumnas";
import { Pagination } from './Pagination';

import {
    actualizarDocumentoDatabase,
    consultarDatabase,
    createUser,
    eliminarDocumentoDatabase,
    guardarDatabase
} from "../config/firebase";

import {
    buscarColumnas,
    calcularRegistrosPagina,
    calcularTotalPaginas,
    crearArregloInicial,
    ordenar,
    toggleOpen,
    validarMobil,
} from '../config/functions';

export const Usuarios = () => {
    const user = JSON.parse(localStorage.getItem("token")).userName;

    const broken = JSON.parse(localStorage.getItem("broken"));
    const collapsed = JSON.parse(localStorage.getItem("collapsed"));

    const fechaActual = new Date();
    const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

    // Paginacion
    const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
    const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
    const [paginaActual, setPaginaActual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(0)
    const [registrosPaginaActual, setRegistrosPaginaActual] = useState([])

    // General
    const [isOpen, setIsOpen] = useState(null);
    const [add, setAdd] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [mobile, setMobile] = useState(true);

    // Usuarios
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [userDelete, setUserDelete] = useState([]);
    const [idDocumento, setIdDocumento] = useState("");
    const [nombre, setNombre] = useState("")
    const [apellido, setApellido] = useState("")
    const [correo, setCorreo] = useState("")
    const [rol, setRol] = useState("")
    const [cambioClave, setCambioClave] = useState(false)
    const [estado, setEstado] = useState("")

    // Usuario
    const [usuarioCreacion, setUsuarioCreacion] = useState(user);
    const [fechaCreacion, setFechaCreacion] = useState(fechaFormateada);
    const usuarioModificacion = user;
    const fechaModificacion = fechaFormateada;

    // Columnas
    const [col2, setCol2] = useState(true)
    const [col3, setCol3] = useState(true)
    const [col4, setCol4] = useState(true)
    const [col5, setCol5] = useState(true)
    const [countOrdenar, setCountOrdenar] = useState(0)


    /*************** Funciones ***********************/
    useEffect(() => {
        cargarUsuarios()
        buscarColumnas([2, 3, 4, 5], 'user', setCol2, setCol3, setCol4, setCol5)
        validarMobil(setMobile)
    }, [])

    useEffect(() => {
        if (add) {
            resetStates();
        }
    }, [add]);

    useEffect(() => {
        setIsOpen(crearArregloInicial(registrosPaginaActual.length))
    }, [registrosPaginaActual])

    const cargarUsuarios = async () => {
        setIsLoading(true)
        try {
            const listaTemporal = await consultarDatabase("usuarios");

            if (listaTemporal.length > 0) {
                setListaUsuarios(listaTemporal.sort(sortBy('consecutivo')));
                setDocumentosFiltrados(listaTemporal.sort(sortBy('consecutivo')));
            } else {
                const temporal = [{
                    nombre: '',
                    apellido: '',
                    correo: '',
                    pass: '',
                    estado: '',
                }]
                setListaUsuarios(temporal);
                setDocumentosFiltrados(temporal)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    };

    const handleClickAdicionar = async () => {
        const passUsuario = bcrypt.hashSync("123456", 10);
        const passAdmin = bcrypt.hashSync("123456", 10);

        const respuesta = listaUsuarios.find(u => u.correo === correo);

        if (respuesta) {
            toast.info("El correo : " + correo + " ya se encuentra registrado, intente de nuevo");
            resetStates();
        } else {
            const llave = rol === "ADMIN" ? passAdmin : passUsuario

            const enviarUser = {
                id: uniqid("usuario-"),
                nombre: nombre,
                apellido: apellido,
                correo: correo,
                clave: llave,
                rol: rol,
                estado: estado,
                cambioClave: true,
                fechaCreacion: fechaCreacion,
                usuarioCreacion: usuarioCreacion.toUpperCase(),
            };

            if (nombre === "" || apellido === "" || correo === "" || rol === "" || estado === "") {
                toast.warning("Diligencie todos los campos de manera correcta");
            } else {
                await guardarDatabase("usuarios", enviarUser);
                await createUser(correo)
                await cargarUsuarios();
                toast.success("Usuario agregado de manera correcta");
                resetStates();
            }
        }
    };

    const handleClickTraerUser = (u) => {

        setIdDocumento(u.idDocumento)
        setNombre(u.nombre)
        setApellido(u.apellido)
        setRol(u.rol)
        setCorreo(u.correo)
        setCambioClave(u.cambioClave)
        setEstado(u.estado)
    };

    const handleClickModificarBd = async () => {

        const enviarUser = {
            nombre: nombre,
            apellido: apellido,
            correo: correo,
            rol: rol,
            estado: estado,
            fechaModificacion: fechaModificacion,
            usuarioModificacion: usuarioModificacion.toUpperCase(),
        };

        if (idDocumento !== "" && idDocumento !== undefined) {
            setIsLoading(true);
            try {
                await actualizarDocumentoDatabase("usuarios", idDocumento, enviarUser);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
            toast.success("Usuario Modificado correctamente");
            await cargarUsuarios();
        } else {
            toast.warning("Seleccione un usuario valido para modificar");
        }
    };

    const handleClickEliminar = async (u) => {
        if (u.idDocumento !== "" && u.idDocumento !== undefined) {
            setIsLoading(true);
            try {
                await eliminarDocumentoDatabase("usuarios", u.idDocumento);
                toast.success("Usuario eliminado correctamente");
                cargarUsuarios()

            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        } else {
            toast.warning("Seleccione un usuario para eliminar");
        }
    };

    const CambioClave = () => {
        const valor = {
            cambioClave: true,
        };
        actualizarDocumentoDatabase("usuarios", idDocumento, valor);
        toast.success("Permiso otorgado exitosamente. Comuníqueselo al usuario.");
        cargarUsuarios();
    };

    const resetStates = () => {
        setNombre("")
        setApellido("")
        setRol("")
        setCorreo("")
        setCambioClave(false)
        setEstado("")
    };

    //---------------------Paginacion--------------------------------
    useEffect(() => {
        setTotalPaginas(calcularTotalPaginas(documentosFiltrados, registrosPorPagina));
    }, [documentosFiltrados, registrosPorPagina]);

    useEffect(() => {
        setRegistrosPaginaActual(calcularRegistrosPagina(documentosFiltrados, paginaActual, registrosPorPagina));
    }, [paginaActual, totalPaginas, countOrdenar]);



    return (
        <>
            {isLoading
                ? <LoaderES />
                : <>
                    <div className="fixed-top "
                        style={{
                            backgroundColor: 'white',
                            marginLeft: broken || mobile ? '10px' : (!collapsed ? '260px' : '90px'),
                            marginRight: broken || mobile ? '10px' : (!collapsed ? '15px' : '15px'),
                            transition: 'margin-left 0.3s ease',
                            top: '46px',
                            zIndex: 1
                        }}
                    >
                        {/* botones de opcion */}
                        <div className="row mb-2">
                            <div className="col-sm-4 align-self-start text-start">

                                {/* Adicionar */}
                                <button
                                    className="btn btn-sm"
                                    data-bs-toggle="modal"
                                    data-bs-target="#ModAdiUser"
                                    title="Crear Usuario"
                                    onClick={() => setAdd(true)} >
                                    <i className="fa-solid fa-plus" />
                                </button>

                                {/* Columnas */}
                                <button
                                    className="btn btn-sm"
                                    title="Seleccionar Columnas"
                                    data-bs-toggle="modal"
                                    data-bs-target="#ModificarColumnas"
                                    id="modalcolumnas" >
                                    <i className="fa-solid fa-table-columns" />
                                </button>

                                {/* Actualizar */}
                                <button
                                    title="Actualizar Datos"
                                    className="btn btn-sm"
                                    onClick={() => {
                                        cargarUsuarios()
                                    }}>
                                    <i className="fa-solid fa-rotate" />
                                </button>

                            </div>

                            {/* filtrar */}
                            <div className="col-sm-8">
                                <Filtrar
                                    tabla="myTable"
                                    documentos={listaUsuarios}
                                    setDocumentosFiltrados={setDocumentosFiltrados}
                                    setRegistrosFiltrados={setRegistrosPaginaActual}
                                    registrosPorPagina={registrosPorPagina} />
                            </div>
                        </div>
                    </div>

                    <hr className={" mt-5 " + (mobile === true ? ' mb-5' : '')} />

                    {mobile
                        ?
                        /* Mobile */
                        <>
                            {registrosPaginaActual?.map((u, index) => (
                                <div key={u.correo + u.id}>
                                    <table className="table table-sm table-hover table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '50px', resize: 'none' }}>
                                                    <button className="btn btn-sm"
                                                        type="button"
                                                        onClick={() => toggleOpen(index, isOpen, setIsOpen)}

                                                        data-bs-target={"#collapseItem" + u.apellido + u.id}
                                                        aria-expanded={isOpen[index]}
                                                        aria-controls="collapseItem" >
                                                        <i className={`fa-solid ${isOpen[index] ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                                                    </button>
                                                </th>
                                                <th style={{ width: '65px', resize: 'none' }}>{u.nombre}</th>
                                                <td style={{ minWidth: '60px', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', resize: 'none' }}>
                                                    <span className="truncate-text">
                                                        {u.rol.length > 50 ? u.rol.slice(0, 50) + '...' : u.rol}
                                                    </span>
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>


                                    <div className={`collapse card card-body ${isOpen[index] ? 'show' : ''}`} id={"collapseItem" + u.apellido + u.id}>
                                        <table className="table table-sm">
                                            <tbody >
                                                <tr>
                                                    <td></td>
                                                    <th style={{ resize: 'none' }} className="text-start">Nombre </th>
                                                    <td className="text-start">{u.nombre}</td>
                                                </tr>

                                                {!col2 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Apellido </th>
                                                        <td className="text-start">{u.apellido}</td>
                                                    </tr>
                                                }

                                                {!col3 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Correo </th>
                                                        <td className="text-start">{u.correo}</td>
                                                    </tr>
                                                }

                                                {!col4 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Rol </th>
                                                        <td className="text-start">{u.rol}</td>
                                                    </tr>
                                                }

                                                {!col5 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Estado </th>
                                                        <td className="text-start">{u.estado}</td>
                                                    </tr>
                                                }


                                                <tr>
                                                    <td></td>
                                                    <th style={{ resize: 'none' }} className="text-start">Actions </th>
                                                    <td className="text-center">
                                                        <div className="btn-group">

                                                            {/* Btn Modificar */}
                                                            <button
                                                                className="btn btn-sm btn-outline-info"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#ModAdiUser"
                                                                title="Modificar Item Seleccionado"
                                                                onClick={() => {
                                                                    setAdd(false)
                                                                    handleClickTraerUser(u)
                                                                }}>
                                                                <i className="fa-regular fa-pen-to-square" />
                                                            </button>

                                                            {/* Btn Eliminar */}
                                                            <button
                                                                className="btn btn-sm btn-outline-danger"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#EliminarUser"
                                                                title="Eliminar Item Seleccionado"
                                                                onClick={() => setUserDelete(u)
                                                                }>
                                                                <i className="fa-regular fa-trash-can" />
                                                            </button>

                                                            {/* +Btn passw */}
                                                            <button className="btn btn-sm btn-outline-primary"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#pass"
                                                                title="Cambiar Contarseña"
                                                                onClick={() => {
                                                                    handleClickTraerUser(u);
                                                                }}>
                                                                <i className="fa-solid fa-unlock-keyhole" />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            ))}
                        </>
                        /* Mobile */
                        :
                        /* Desktop */
                        <div className="table-responsive">
                            <table
                                id="myTable"
                                className="table table-hover table-striped table-sm table-bordered align-middle">

                                <thead className="text-center">
                                    <tr >
                                        <th onClick={() => ordenar('nombre', countOrdenar, documentosFiltrados, [], setCountOrdenar)}>Nombre</th>
                                        <th onClick={() => ordenar('apellido', countOrdenar, documentosFiltrados, [], setCountOrdenar)} className={col2 ? '' : 'd-none'}>Apellido</th>
                                        <th onClick={() => ordenar('correo', countOrdenar, documentosFiltrados, [], setCountOrdenar)} className={col3 ? '' : 'd-none'}>Correo</th>
                                        <th onClick={() => ordenar('rol', countOrdenar, documentosFiltrados, [], setCountOrdenar)} className={col4 ? '' : 'd-none'}>Rol</th>
                                        <th onClick={() => ordenar('estado', countOrdenar, documentosFiltrados, [], setCountOrdenar)} className={col5 ? '' : 'd-none'}>Estado</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="text-center" id="tabla_articulos">

                                    {registrosPaginaActual.length === 1 && registrosPaginaActual[0].correo === '' ? null

                                        : registrosPaginaActual?.map((u) => (
                                            <tr key={u.correo + u.id}>
                                                <td title={!u.cambioClave ? "" : "Tiene pendiente el cambio de clave"}
                                                    className={u.cambioClave ? "text-danger" : ""}>
                                                    {!u.cambioClave
                                                        ? u.nombre
                                                        : u.nombre + " * "}
                                                </td>
                                                <td className={col2 ? '' : 'd-none'}>{u.apellido}</td>
                                                <td className={col3 ? '' : 'd-none'}>{u.correo}</td>
                                                <td className={col4 ? '' : 'd-none'}>{u.rol}</td>
                                                <td className={col5 ? '' : 'd-none'}>{u.estado}</td>

                                                {/* Btns */}
                                                <td className="text-center">
                                                    <div className="btn-group">

                                                        {/* Btn Modificar */}
                                                        <button
                                                            className="btn btn-sm btn-outline-info"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#ModAdiUser"
                                                            title="Modificar Item Seleccionado"
                                                            onClick={() => {
                                                                setAdd(false)
                                                                handleClickTraerUser(u)
                                                            }}>
                                                            <i className="fa-regular fa-pen-to-square" />
                                                        </button>

                                                        {/* Btn Eliminar */}
                                                        <button
                                                            className="btn btn-sm btn-outline-danger"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#EliminarUser"
                                                            title="Eliminar Item Seleccionado"
                                                            onClick={() => setUserDelete(u)}
                                                        >
                                                            <i className="fa-regular fa-trash-can" />
                                                        </button>

                                                        {/* +Btn passw */}
                                                        <button className="btn btn-sm btn-outline-primary"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#pass"
                                                            title="Cambiar Contarseña"
                                                            onClick={() => {
                                                                handleClickTraerUser(u);
                                                            }}
                                                        >
                                                            <i className="fa-solid fa-unlock-keyhole" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    }

                    <Pagination
                        paginaActual={paginaActual}
                        totalPaginas={totalPaginas}
                        setPaginaActual={setPaginaActual}
                        registrosPorPagina={registrosPorPagina}
                        setRegistrosPorPagina={setRegistrosPorPagina}
                        documentosFiltrados={documentosFiltrados}
                    />

                    {/* + Modal de Modificar // Adicionar monitores*/}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="ModAdiUser"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className={add ? "modal-header bg-primary text-light justify-content-center" : "modal-header bg-info text-secondary justify-content-center"}>
                                    <h5 className="modal-title fs-5">
                                        {add ? "Crear " : "Modificar "} Usuario
                                    </h5>
                                </div>

                                {/* Formulario */}
                                <div className="modal-body">

                                    {/* Nombre */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Nombre:</span>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={nombre}
                                            onChange={(e) => setNombre(e.target.value.toUpperCase())}
                                        />
                                    </div>

                                    {/* Apellido  */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Apellido:</span>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={apellido}
                                            onChange={(e) => setApellido(e.target.value.toUpperCase())} />
                                    </div>

                                    {/* Correo  */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Correo:</span>
                                        <input
                                            className="form-control"
                                            type="email"
                                            value={correo}
                                            disabled={!add}
                                            onChange={(e) => setCorreo(e.target.value)} />
                                    </div>

                                    {/* rol */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Rol:</span>
                                        <select
                                            className="form-select"
                                            placeholder="Rol"
                                            value={rol}
                                            onChange={(e) => { setRol(e.target.value) }} >
                                            <option value="" disabled> Seleccione </option>
                                            <option value="ADMIN">Admin</option>
                                            <option value="USUARIO">Usuario</option>
                                        </select>
                                    </div>

                                    {/* Estado */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Estado: </span>
                                        <select
                                            className="form-select"
                                            value={estado}
                                            onChange={(e) => setEstado(e.target.value)}>
                                            <option value="" disabled>Seleccione</option>
                                            <option value="ACTIVO">Activo</option>
                                            <option value="INACTIVO">Inactivo</option>
                                        </select>
                                    </div>

                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className={add ? "btn btn-sm btn-primary text-light" : "btn btn-sm btn-info text-secondary"}
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            add ?
                                                handleClickAdicionar()
                                                : handleClickModificarBd()
                                        }}
                                    >  {add ? "Crear" : "Modificar"}
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        data-bs-dismiss="modal"
                                        onClick={() => resetStates()}
                                    > Cancelar </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal de Modificar // Adicionar */}

                    {/* +Modal Eliminar */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="EliminarUser"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className="modal-header bg-danger text-white justify-content-center">
                                    <h5 className="modal-title  " id="staticBackdropLabel3">
                                        Alerta Eliminar Usuario
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <div className="modal-body text-center">
                                        <p>
                                            Está seguro de eliminar <br /> el Usuario:<strong>  {' ' + userDelete.nombre}</strong>, <br />
                                            <span className="text-danger mt-2">
                                                Recuerde que esta acción no se puede revertir.
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        data-bs-dismiss="modal"
                                        id="btnEliminarArticulo"
                                        onClick={() => handleClickEliminar(userDelete)}
                                    >
                                        Si, eliminar
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal Eliminar */}

                    {/* +Modal permiso cambio clave */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="pass"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel3">
                                        Permiso Para Cambio De Contraseña
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <div className="modal-body">
                                        <p>
                                            {!cambioClave
                                                ? "Desea permitir que el usuario pueda cambiar su clave?"
                                                : "El usuario todavía tiene pendiente el cambio de clave."}
                                        </p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-dismiss="modal"
                                        id="btnEliminarArticulo"
                                        hidden={!cambioClave ? false : true}
                                        onClick={CambioClave}
                                    >
                                        Permitir
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal permiso cambio clave */}

                    {/* +Modal Columnas */}
                    <ModalCoumnas
                        columnas={['Apellido', 'Correo', 'Rol', 'Estado']}
                        identificador={'user'}
                        col2={col2} col3={col3} col4={col4} col5={col5}
                        setCol2={setCol2} setCol3={setCol3} setCol4={setCol4} setCol5={setCol5} />
                </>
            }
        </>
    )
}

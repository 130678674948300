import React, { useEffect, useState } from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useLocation } from 'react-router-dom';
import logo2 from '../assets/img/logo2.png';
import '../css/carrousel.css';
import Footer from './Footer';
import NavBar from './NavBar';

import { obtenerURLImagen } from '../config/firebase';
import { validarMobil } from '../config/functions';
import '../css/articulo.css';
import { LoaderES } from './LoaderES';

export const Articulo = () => {

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState(true);

  const articulo = location.state.articulo
  const [listaImg, setListaImg] = useState(null)

  useEffect(() => {
    if (articulo?.listaImg) {
      cargarImagenes()
    }
  }, [articulo])

  const cargarImagenes = async () => {
    try {
      const arreglo = [...articulo.listaImg];
      const temporal = [];

      for (const l of arreglo) {
        const url = await getImage(l);
        const tempo = { original: url, thumbnail: url };
        temporal.push(tempo);
      }

      setListaImg(temporal);
    } catch (error) {
      console.error("Error al cargar imágenes:", error);
    }
  };

  const getImage = async (u) => {
    const response = await obtenerURLImagen(u)
    return response
  }

  useEffect(() => {
    validarMobil(setMobile)
  }, [])



  return (
    <>
      <FloatingWhatsApp
        phoneNumber="57 3504825308"
        accountName="Heritage Group"
        allowEsc
        allowClickAway
        notification
        notificationSound
        //statusMessage='Control-Manage-Expand'
        chatMessage={`Tienes alguna pregunta sobre el artículo con Ref: ${articulo.ref} ? `}
        buttonClassName="me-4"
        buttonStyle={{ marginBottom: '80px' }}
        chatboxStyle={{ marginBottom: '70px' }}
        avatar={logo2}
      />
      <NavBar />

      <div className="container marketing pt-5">
        {isLoading || !listaImg ?
          <LoaderES />
          :
          <>
            <div className="card mb-3 mt-5 pt-5" >
              <div className="row g-0">
                <div className="col-md-4">
                  <ImageGallery
                    items={listaImg}
                    lazyLoad={true}
                    /* thumbnailPosition='left' */
                    showFullscreenButton={false} />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title">{articulo.ref}</h5>
                    <p className="card-text">
                      {articulo.categoria !== "NUC" || articulo.categoria !== "COMPUTADORES" || articulo.categoria !== "CORPORATIVOS" ?
                        <>
                          {articulo.marca !== "-" ? <><strong>Marca: </strong>{articulo.marca}</> : null}
                        </>
                        : null}

                      {articulo.categoria === "CASE" ?
                        <>
                          {articulo.estructuraLateral !== "-" ? <><br /> <strong> Estr. lateral: </strong>{articulo.estructuraLateral}</> : null}
                          {articulo.estructuraFrontal !== "-" ? <><br /> <strong> Estr. frontal: </strong>{articulo.estructuraFrontal}</> : null}
                          {articulo.audio !== "-" ? <><br /> <strong>Audio: </strong>{articulo.audio}</> : null}
                          {articulo.filtro !== "-" ? <><br /> <strong> Filtro: </strong>{articulo.filtro}</> : null}
                          {articulo.usb !== "-" ? <><br /> <strong> USB: </strong>{articulo.usb}</> : null}
                          {articulo.ventiladores !== "-" ? <><br /> <strong> Ventiladores: </strong>{articulo.ventiladores}</> : null}
                          {articulo.observaciones !== "" ? <><br /> <strong> Observaciones: </strong>{articulo.observaciones}</> : null}
                          {articulo.valor !== "-" ? <><br /> <strong> Precio: </strong> ${Intl.NumberFormat("es-CO").format(articulo.valor)}</> : null}
                        </>
                        : null}

                      <br />{articulo.categoria === "NUC" || articulo.categoria === "COMPUTADORES" || articulo.categoria === "CORPORATIVOS" ?
                        <>
                          {articulo.procesador !== "-" ? <><strong>Procesador: </strong> {articulo.procesador}</> : null}
                          {articulo.board !== "-" ? <><br /> <strong>Board: </strong>{articulo.board}</> : null}
                          {articulo.disco !== "-" ? <><br /> <strong>Disco: </strong>{articulo.disco}</> : null}
                          {articulo.memoria !== "-" ? <><br /> <strong>Memoria: </strong>{articulo.memoria}</> : null}
                          {articulo.monitor !== "-" ? <><br /> <strong>Monitor: </strong>{articulo.monitor}</> : null}
                          {articulo.kase !== "-" ? <><br /> <strong>Case: </strong>{articulo.kase}</> : null}
                          {articulo.accesorios !== "-" ? <><br /> <strong>Accesorios: </strong>{articulo.accesorios}</> : null}
                          {articulo.fuente !== "-" ? <><br /> <strong>Fuente: </strong>{articulo.fuente}</> : null}
                          {articulo.garantia !== "-" ? <><br /> <strong>Garantia: </strong>{articulo.garantiaNormal}</> : null}
                          {articulo.garantiaExtendida !== "-" ? <><br /> <strong>Garantia Ext.: </strong>{articulo.garantiaExtendida}</> : null}
                          {articulo.sistema !== "-" ? <><br /> <strong>Sistema: </strong>{articulo.sistema}</> : null}
                          {articulo.valor !== "-" ? <><br /> <strong> Precio: </strong> ${Intl.NumberFormat("es-CO").format(articulo.valor)}</> : null}
                        </>
                        : null}

                      {articulo.categoria === "MONITORES" ? <>
                        {articulo.tamanoImg !== "-" ? <><strong> Tamaño Img: </strong>{articulo.tamanoImg}</> : null}
                        {articulo.resolucionPantalla !== "-" ? <><br /> <strong> Resolucion: </strong>{articulo.resolucionPantalla}</> : null}
                        {articulo.anguloVision !== "-" ? <><br /> <strong> Angulo Vision: </strong>{articulo.anguloVision}</> : null}
                        {articulo.tipoConexion !== "-" ? <><br /> <strong> Tipo Conexion: </strong>{articulo.tipoConexion}</> : null}
                        {articulo.tipoResolucion !== "-" ? <><br /> <strong> Tipo Resolucion: </strong>{articulo.tipoResolucion}</> : null}
                        {articulo.valor !== "-" ? <><br /> <strong> Precio: </strong> ${Intl.NumberFormat("es-CO").format(articulo.valor)}</> : null}
                      </> : null}

                      {articulo.categoria === "ACCESORIOS" || articulo.categoria === "SOFTWARE" ? <>
                        {articulo.nombre !== "-" ? <><strong> Nombre: </strong>{articulo.nombre}</> : null}
                        {articulo.descripcion !== "-" ? <> <br /><strong> Descripcion: </strong>{articulo.descripcion}</> : null}
                        {articulo.valor !== "-" ? <><br /> <strong> Precio: </strong> ${Intl.NumberFormat("es-CO").format(articulo.valor)}</> : null}

                      </> : null}
                    </p>

                    {/* btn cotizar */}
                    <div className="row justify-content-center mt-3">
                      <div className="col-auto">
                        <a className='btn  btn-primary text-center' target='_blank' href={`https://wa.me/+573007256149?text=Me%20interesa%20este%20artículo:%0ACategoría:%20${articulo.categoria},%0AReferencia:%20${articulo.ref},%0AMe%20puedes%20dar%20mas%20información`}>Cotizar Artículo</a>
                      </div>
                    </div>

                    {/* <p className="card-text"><small className="text-body-secondary">Last updated 3 mins ago</small></p> */}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-lg-6 col-md-6 mb-4 mb-md-0">
                <div className="product-image">
                  <div className="product_img_box">
                    <img id="product_img" src='assets/images/product_img1.jpg' data-zoom-image="assets/images/product_zoom_img1.jpg" alt="product_img1" />
                    <a href="#" className="product_img_zoom" title="Zoom">
                      <span className="linearicons-zoom-in"></span>
                    </a>
                  </div>
                  <div id="pr_item_gallery" className="product_gallery_item slick_slider" data-slides-to-show="4" data-slides-to-scroll="1" data-infinite="false">
                    <div className="item">
                      <a href="#" className="product_gallery_item active" data-image="assets/images/product_img1.jpg" data-zoom-image="assets/images/product_zoom_img1.jpg">
                        <img src="assets/images/product_small_img1.jpg" alt="product_small_img1" />
                      </a>
                    </div>
                    <div className="item">
                      <a href="#" className="product_gallery_item" data-image="assets/images/product_img1-2.jpg" data-zoom-image="assets/images/product_zoom_img2.jpg">
                        <img src="assets/images/product_small_img2.jpg" alt="product_small_img2" />
                      </a>
                    </div>
                    <div className="item">
                      <a href="#" className="product_gallery_item" data-image="assets/images/product_img1-3.jpg" data-zoom-image="assets/images/product_zoom_img3.jpg">
                        <img src="assets/images/product_small_img3.jpg" alt="product_small_img3" />
                      </a>
                    </div>
                    <div className="item">
                      <a href="#" className="product_gallery_item" data-image="assets/images/product_img1-4.jpg" data-zoom-image="assets/images/product_zoom_img4.jpg">
                        <img src="assets/images/product_small_img4.jpg" alt="product_small_img4" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="pr_detail">
                  <div className="product_description">
                    <h4 className="product_title"><a href="#">{articulo.ref}</a></h4>
                    <div className="product_price">
                      <span className="price">{`$${articulo.valor}.00`}</span>
                      <del>{`$${articulo.valor + 5}.00`}</del>
                      <div className="on_sale">
                        <span>35% Off</span>
                      </div>
                    </div>
                    <div className="rating_wrap">
                      <div className="rating">
                        <div className="product_rate" style={{ width: "80%" }}></div>
                      </div>
                      <span className="rating_num">(21)</span>
                    </div>
                    <div className="pr_desc">
                      <p>{articulo.descripcion}</p>
                    </div>
                    <div className="product_sort_info">
                      <ul>
                        <li><i className="linearicons-shield-check"></i> 1 Year AL Jazeera Brand Warranty</li>
                        <li><i className="linearicons-sync"></i> 30 Day Return Policy</li>
                        <li><i className="linearicons-bag-dollar"></i> Cash on Delivery available</li>
                      </ul>
                    </div>
                    <div className="pr_switch_wrap">
                      <span className="switch_lable">Color</span>
                      <div className="product_color_switch">
                        <span className="active" data-color="#87554B"></span>
                        <span data-color="#333333"></span>
                        <span data-color="#DA323F"></span>
                      </div>
                    </div>
                    <div className="pr_switch_wrap">
                      <span className="switch_lable">Size</span>
                      <div className="product_size_switch">
                        <span>xs</span>
                        <span>s</span>
                        <span>m</span>
                        <span>l</span>
                        <span>xl</span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="cart_extra">
                    <div className="cart-product-quantity">
                      <div className="quantity">
                        <input type="button" value="-" className="minus" />
                        <input type="text" name="quantity" value="1" title="Qty" className="qty" size="4" />
                        <input type="button" value="+" className="plus" />
                      </div>
                    </div>
                    <div className="cart_btn">
                      <button className="btn btn-fill-out btn-addtocart" type="button"><i className="icon-basket-loaded"></i> Add to cart</button>
                      <a className="add_compare" href="#"><i className="icon-shuffle"></i></a>
                      <a className="add_wishlist" href="#"><i className="icon-heart"></i></a>
                    </div>
                  </div>
                  <hr />
                  <ul className="product-meta">
                    <li>SKU: <a href="#">BE45VGRT</a></li>
                    <li>Category: <a href="#">Clothing</a></li>
                    <li>Tags: <a href="#" rel="tag">Cloth</a>, <a href="#" rel="tag">printed</a> </li>
                  </ul>

                  
                </div>
              </div>
            </div> */}

          </>
        }

      </div >

      <Footer />
    </>
  )
}

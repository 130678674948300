
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import sortBy from "sort-by";
import uniqid from "uniqid";
import { Filtrar } from "./Filtrar";
import { LoaderES } from "./LoaderES";
import ModalCoumnas from "./ModalColumnas";
import { Pagination } from "./Pagination";

import {
    actualizarDocumentoDatabaseIdCampo,
    actualizarImagen,
    consultarDocumentoDatabaseObjectWhere,
    eliminarDocumentoDatabaseCampo,
    eliminarImagen,
    guardarDatabaseId,
    obtenerURLImagen,
    subirImagen
} from "../config/firebase";

import {
    buscarColumnas,
    calcularRegistrosPagina,
    calcularTotalPaginas,
    cargarConsecutivos,
    cargarMarcas,
    clearInputFile,
    crearArregloInicial,
    fixConsecutivo,
    limpiar,
    manipularConsecutivos,
    modificarConsecutivos,
    ordenar,
    toggleOpen,
    validarMobil
} from "../config/functions";

export const Software = () => {
    const user = JSON.parse(localStorage.getItem("token")).userName;

    const broken = JSON.parse(localStorage.getItem("broken"));
    const collapsed = JSON.parse(localStorage.getItem("collapsed"));

    const fechaActual = new Date();
    const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

    // Paginacion
    const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
    const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
    const [paginaActual, setPaginaActual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(0)
    const [registrosPaginaActual, setRegistrosPaginaActual] = useState([])

    // General
    const [isOpen, setIsOpen] = useState(null);
    const [fileOk, setFileOk] = useState(false);
    const [copy, setCopy] = useState(false);
    const [add, setAdd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [mobile, setMobile] = useState(true);
    const [marcasOk, setMarcasOk] = useState(false);
    const [consecOk, setConsecOk] = useState(false);

    // Software
    const categoria = "SOFTWARE";
    const [listaSoftware, aetListaSoftware] = useState([]);
    const [listaMarcas, setListaMarcas] = useState([]);
    const [listImgSelec, setListImgSelec] = useState([]);
    const [consecutivo, setConsecutivo] = useState("")
    const [marca, setMarca] = useState("")
    const [ref, setRef] = useState("")
    const [nombre, setNombre] = useState("")
    const [descripcion, setDescripcion] = useState("")
    const [valor, setValor] = useState(0)
    const [estado, setEstado] = useState("")

    // Consecutivos
    const [listaConsecSof, setListaConsecSof] = useState([]);
    const [verConsecSof, setVerConsecSof] = useState("");
    const [idDocConsecSof, setIdDocConsecSof] = useState("");
    const [consecutivoEliminar, setConsecutivoEliminar] = useState("");

    // Usuario
    const [usuarioCreacion, setUsuarioCreacion] = useState(user)
    const [fechaCreacion, setFechaCreacion] = useState(fechaFormateada)
    const usuarioModificacion = user;
    const fechaModificacion = fechaFormateada

    // Imágenes
    const [listUrlImg, setListUrlImg] = useState([]);
    const [rutaEnFirestore, setRutaEnFirestore] = useState('');
    const [updateImg, setUpdateImg] = useState(false);
    const [deleteImg, setDeleteImg] = useState(false);
    const [addImg, setAddImg] = useState(false);
    const [imagenURLS, setImagenURLS] = useState([]);
    const [imgActualizar, setImgActualizar] = useState("");
    const [imgBorrar, setImgBorrar] = useState("");
    const [imgNew, setImgNew] = useState("");
    const [cantImg, setCantImg] = useState(0);
    const [color, setColor] = useState("");
    const inputFile = useRef(null);
    const rutaArticulos = "gs://hg-web-e5bb6.appspot.com/articulos/software";

    // Columnas
    const [col2, setCol2] = useState(true)
    const [col3, setCol3] = useState(true)
    const [col4, setCol4] = useState(true)
    const [col5, setCol5] = useState(true)
    const [col6, setCol6] = useState(true)
    const [col7, setCol7] = useState(true)
    const [col8, setCol8] = useState(true)
    const [countOrdenar, setCountOrdenar] = useState(0)


    /*************** Funciones ***********************/
    useEffect(() => {
        cargarSoftware()
        cargarConsecutivos("SFT", setConsecOk, setListaConsecSof, categoria)
        buscarColumnas([2, 3, 4, 5, 6, 7, 8], 'soft', setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8)
        validarMobil(setMobile)
    }, [])

    useEffect(() => {
        if (add) {
            resetStates();
        }
    }, [add]);

    useEffect(() => {
        setIsOpen(crearArregloInicial(registrosPaginaActual.length))
    }, [registrosPaginaActual])

    const cargarSoftware = async () => {
        setIsLoading(true)
        try {
            const listaTemporal = await consultarDocumentoDatabaseObjectWhere("articulos", "articulos", 'categoria', 'SOFTWARE');

            if (listaTemporal.length > 0) {
                const consecOrden = [...listaTemporal]
                consecOrden.forEach(p => {
                    p.consecutivoOk = fixConsecutivo(p.consecutivo)
                });
                aetListaSoftware(consecOrden.sort(sortBy('consecutivoOk')));
                setDocumentosFiltrados(consecOrden.sort(sortBy('consecutivoOk')))
            } else {
                const temporal = [{
                    consecutivo: '',
                    categoria: '',
                    marca: '',
                    ref: '',
                    nombre: '',
                    descripcion: '',
                    valor: '',
                    estado: '',
                }]
                aetListaSoftware(temporal);
                setDocumentosFiltrados(temporal)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    };

    const handleClickAdicionar = async () => {

        const respuesta = listaSoftware.find(articulo => articulo.consecutivo === verConsecSof);
        if (respuesta) {
            toast.info("El Software de consecutivo : " + respuesta.consecutivo + " ya se encuentra registrado, intente de nuevo");
            resetStates();
        } else {
            const enviarArticulo = {
                id: uniqid("articulo-"),
                consecutivo: consecutivo,
                categoria: categoria.toUpperCase(),
                marca: marca.toUpperCase(),
                ref: ref.toUpperCase(),
                listaImg: listUrlImg,
                nombre: nombre.toUpperCase(),
                descripcion: descripcion ? descripcion.toUpperCase() : "",
                valor: parseFloat(valor),
                estado: estado.toUpperCase(),
                fechaCreacion: fechaCreacion,
                usuarioCreacion: usuarioCreacion.toUpperCase(),
            };
            const enviar = {
                [enviarArticulo.consecutivo]: enviarArticulo
            };
            if (consecutivo === "" || categoria === "" || marca === "" || ref === "" || nombre === "" || listUrlImg.length < 1 || valor === "" || valor === 0 || estado === "") {
                toast.warning("Diligencie todos los campos de manera correcta");
            } else {
                await guardarDatabaseId("articulos", "articulos", enviar);
                await handleSubirImagenes(2, imgNew, rutaEnFirestore);
                setVerConsecSof("")
                await modificarConsecutivos(verConsecSof, idDocConsecSof, listaConsecSof, setIdDocConsecSof, setVerConsecSof, "SFT", setConsecOk, setListaConsecSof, setConsecutivo, categoria);
                await cargarSoftware();
                toast.success(`Artículo agregado de manera correcta`)
                resetStates();
            }
        }
    };

    const handleClickTraerArticulo = (a) => {
        setConsecutivo(a.consecutivo)
        setMarca(a.marca)
        setRef(a.ref)
        setNombre(a.nombre)
        setListUrlImg(a.listaImg)
        setDescripcion(a.descripcion)
        setValor(a.valor)
        setEstado(a.estado)
        setFechaCreacion(a.fechaCreacion)
        setUsuarioCreacion(a.usuarioCreacion)
    };

    const handleClickModificarBd = async (tipo) => {

        let listaImg = (tipo === "AddImg"
            ? [...listUrlImg, rutaEnFirestore]
            : (tipo === "DelImg"
                ? listUrlImg.filter(item => item !== imgBorrar.ruta)
                : listUrlImg));

        const enviarArticulo = {
            consecutivo: consecutivo,
            categoria: categoria.toUpperCase(),
            marca: marca.toUpperCase(),
            ref: ref.toUpperCase(),
            nombre: nombre.toUpperCase(),
            descripcion: descripcion.toUpperCase(),
            listaImg: listaImg,
            valor: parseFloat(valor),
            estado: estado.toUpperCase(),
            fechaCreacion: fechaCreacion,
            usuarioCreacion: usuarioCreacion.toUpperCase(),
            fechaModificacion: fechaModificacion,
            usuarioModificacion: usuarioModificacion.toUpperCase(),
        };

        if (consecutivo !== "" && consecutivo !== undefined) {
            setIsLoading(true);
            try {
                await actualizarDocumentoDatabaseIdCampo("articulos", "articulos", consecutivo, enviarArticulo);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
            toast.success("Artículo Modificado correctamente");
            await cargarSoftware();
            resetStates();
        } else {
            toast.warning("Seleccione un artículo valido para modificarlo");
        }
    };

    const handleClickEliminar = async () => {
        if (consecutivoEliminar !== "" && consecutivoEliminar !== undefined) {
            setIsLoading(true);
            try {
                const respuesta = await eliminarDocumentoDatabaseCampo("articulos", "articulos", consecutivoEliminar);
                if (respuesta) {
                    await handleEliminarImagen(2, imgBorrar, resetStates, imagenURLS);
                    toast.success(respuesta);
                    cargarSoftware()
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        } else {
            toast.warning("Seleccione un Software para eliminar");
        }
    };

    const resetStates = () => {
        setConsecutivo("")
        setMarca("")
        setRef("")
        setNombre("")
        setDescripcion("")
        setValor(0)
        setEstado("")
        setFileOk(false)
        if (add || copy) { clearInputFile(inputFile) }
    };

    //---Consecutivos-----------
    useEffect(() => {
        manipularConsecutivos(listaConsecSof, setIdDocConsecSof, setVerConsecSof, setConsecutivo);
    }, [listaConsecSof]);

    //------------Marcas-----------
    useEffect(() => {
        cargarMarcas().then((mr) => {
            if (mr.lstCons.length > 0) {
                if (mr.lstAct.length > 0) {
                    setMarcasOk(true)
                    setListaMarcas(mr.lstAct)
                } else {
                    setMarcasOk(false)
                    toast.warning("No hay marcas activas, para la creacion de Software, por favor validar")
                }
            } else {
                setMarcasOk(false)
                toast.warning("No hay marcas para la creacion de Software, por favor crearlas")
            }
        })
    }, [])

    //----------CRUD imágenes----------------
    const handleImageSelect = (event, tipo) => {
        if (tipo === 1) {
            const image = event.target.files[0];
            if (image) {
                setFileOk(true)
                setImgNew(image);
                let url = `${rutaArticulos}/${consecutivo}/img-${image.name}/${image.name}`;
                setRutaEnFirestore(url)
            } else {
                setFileOk(false)
                toast.warning('Debe seleccionar una imágen para guardar, intente nuevamente')
            }
        } else if (tipo === 2) {
            let lsImg = []
            const images = event.target.files;
            setListImgSelec(images)
            for (let i = 0; i < images.length; i++) {
                let listImgSelec = images[i];
                if (listImgSelec) {
                    let url = `${rutaArticulos}/${verConsecSof}/img-${listImgSelec.name}/${listImgSelec.name}`;
                    lsImg = [...lsImg, url]
                }
            }
            if (lsImg.length > 5) {
                toast.warning("El maximo de imágenes permitida es de 5, verifica e intenta de nuevo")
                lsImg = []
                setFileOk(false)
                clearInputFile(inputFile)
            } else {
                setFileOk(true)
                setListUrlImg(lsImg)
            }
        } else if (tipo === 3) {
            const image = event.target.files[0];
            if (image) {
                setFileOk(true)
                setImgNew(image);
                setRutaEnFirestore(imgActualizar.url)
            } else {
                setFileOk(false)
                toast.warning('Debe seleccionar una imágen para guardar, intente nuevamente')
            }
        }
    };

    const handleSubirImagenes = async (tipo, img, ruta) => {
        if (tipo === 1) {
            if (img && ruta) {
                await subirImagen(ruta, img);
                toast.success("La imágen fue cargada correctamente")
                setFileOk(false)
                setImgNew('')
            }
        } else if (tipo === 2) {
            if (listUrlImg.length !== listImgSelec.length) {
                console.error('Las listas no tienen la misma longitud');
                return;
            }
            let images = listUrlImg.map((url, index) => ({ url, img: listImgSelec[index] }));
            for (let image of images) {
                if (image.img && image.url) {
                    await subirImagen(image.url, image.img);
                }
            }
            setListUrlImg([]);
            setListImgSelec([]);
            setFileOk(false);
            toast.success("Las imágenes fueron cargadas correctamente");
        }
    };

    const handleEliminarImagen = async (tipo) => {
        if (tipo === 1) {
            if (imgBorrar) {
                await eliminarImagen(imgBorrar.url);
                toast.success('La imagen fue eliminada correctamente')
                resetStates()
            }
        } else if (tipo === 2) {
            if (imagenURLS.length > 0) {
                for (let urlImg of imagenURLS) {
                    if (urlImg) {
                        await eliminarImagen(urlImg.url);
                    }
                }
            }
        }
    };

    const handleActualizarImagen = async () => {
        if (imgNew && rutaEnFirestore) {
            await actualizarImagen(rutaEnFirestore, imgNew);
            toast.success('La imágen fue actualizada correctamente')
            setImgNew("")
            resetStates()
        } else {
            toast.warning('Debe seleccionar una imágen para modificar, intente nuevamente')
            setImgNew("")
        }
    };

    const handleObtenerURLImagen = async (rutas) => {
        let im = []
        if (rutas.length > 0) {
            for (let ruta of rutas) {
                if (ruta) {
                    let r = await obtenerURLImagen(ruta);
                    let obj = { ruta: ruta, url: r };
                    im = [...im, obj]
                }
            }
            setImagenURLS(im)
            setCantImg(im.length)
        } else {
            toast.warning('El artículo no tiene imágenes disponibles, debe cargarlas en este modal')
            setCantImg(0)
        }
    };

    //---------Paginacion----------
    useEffect(() => {
        setTotalPaginas(calcularTotalPaginas(documentosFiltrados, registrosPorPagina));
    }, [documentosFiltrados, registrosPorPagina]);

    useEffect(() => {
        setRegistrosPaginaActual(calcularRegistrosPagina(documentosFiltrados, paginaActual, registrosPorPagina));
    }, [paginaActual, totalPaginas, countOrdenar]);

    //-------------------------------------------------
    const nuevaImagen = async (num) => {
        await handleClickModificarBd("AddImg")
        await handleSubirImagenes(num, imgNew, rutaEnFirestore)
        limpiar(setUpdateImg, setDeleteImg, setAddImg, setCantImg)
    }


    return (
        <>
            {isLoading
                ? <LoaderES />
                : <>
                    {/* botones de opcion */}
                    <div className="fixed-top "
                        style={{
                            backgroundColor: 'white',
                            marginLeft: broken || mobile ? '10px' : (!collapsed ? '260px' : '90px'),
                            marginRight: broken || mobile ? '10px' : (!collapsed ? '15px' : '15px'),
                            transition: 'margin-left 0.3s ease',
                            top: '46px',
                            zIndex: 1
                        }}>

                        <div className="row mb-2">
                            <div className="col-sm-4 align-self-start text-start">
                                <div className="btn-group">

                                    {/* Adicionar */}
                                    {verConsecSof !== "" ?
                                        <button
                                            className="btn btn-sm"
                                            data-bs-toggle="modal"
                                            data-bs-target="#ModifAdSof"
                                            title="Crear Nuevo Artículo"
                                            onClick={() => {
                                                setAdd(true)
                                                setCopy(false)
                                            }} >
                                            <i className="fa-solid fa-plus" />
                                        </button>
                                        : null}

                                    {/* Columnas */}
                                    <button
                                        className="btn btn-sm"
                                        title="Seleccionar Columnas"
                                        data-bs-toggle="modal"
                                        data-bs-target="#ModificarColumnas"
                                        id="modalcolumnas" >
                                        <i className="fa-solid fa-table-columns" />
                                    </button>

                                    {/* Actualizar */}
                                    <button
                                        title="Actualizar Datos"
                                        className="btn btn-sm"
                                        onClick={() => cargarSoftware()}>
                                        <i className="fa-solid fa-rotate" />
                                    </button>

                                </div>
                            </div>

                            {/* filtrar */}
                            <div className="col-sm-8">
                                <Filtrar
                                    tabla="myTable"
                                    documentos={listaSoftware}
                                    setDocumentosFiltrados={setDocumentosFiltrados}
                                    setRegistrosFiltrados={setRegistrosPaginaActual}
                                    registrosPorPagina={registrosPorPagina} />
                            </div>
                        </div>
                    </div>

                    <hr className={" mt-5 " + (mobile === true ? ' mb-5' : '')} />

                    {mobile
                        ?
                        /* Mobile */
                        <>
                            {registrosPaginaActual?.map((a, index) => (
                                <div key={a.consecutivo + a.id}>
                                    <table className="table table-sm table-hover table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '50px', resize: 'none' }}>
                                                    <button className="btn btn-sm"
                                                        type="button"
                                                        onClick={() => toggleOpen(index, isOpen, setIsOpen)}
                                                        data-bs-target={"#collapseItem" + a.consecutivo}
                                                        aria-expanded={isOpen[index]}
                                                        aria-controls="collapseItem" >
                                                        <i className={`fa-solid ${isOpen[index] ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                                                    </button>
                                                </th>
                                                <th style={{ width: '65px', resize: 'none' }}>{a.consecutivo}</th>
                                                <td style={{ minWidth: '60px', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', resize: 'none' }}>
                                                    <span className="truncate-text">
                                                        {a.ref.length > 50 ? a.ref.slice(0, 50) + '...' : a.ref}
                                                    </span>
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>

                                    <div className={`collapse card card-body ${isOpen[index] ? 'show' : ''}`} id={"collapseItem" + a.consecutivo}>
                                        <table className="table table-sm">
                                            <tbody >
                                                <tr>
                                                    <td></td>
                                                    <th style={{ resize: 'none' }} className="text-start">Consecutivo </th>
                                                    <td className="text-start">{a.consecutivo}</td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <th style={{ resize: 'none' }} className="text-start">Cant. Img </th>
                                                    <td className="text-start">{a.listaImg.length}</td>
                                                </tr>

                                                {!col2 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Categoria </th>
                                                        <td className="text-start">{a.categoria}</td>
                                                    </tr>
                                                }

                                                {!col3 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Marca </th>
                                                        <td className="text-start">{a.marca}</td>
                                                    </tr>
                                                }

                                                {!col4 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Referencia </th>
                                                        <td className="text-start">{a.ref}</td>
                                                    </tr>
                                                }

                                                {!col5 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Nombre </th>
                                                        <td className="text-start">{a.nombre}</td>
                                                    </tr>
                                                }

                                                {!col6 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Descripcion </th>
                                                        <td className="text-start">{a.descripcion}</td>
                                                    </tr>
                                                }

                                                {!col7 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Valor </th>
                                                        <td className="text-start">$
                                                            {Intl.NumberFormat("es-CO").format(a.valor)}
                                                        </td>
                                                    </tr>
                                                }

                                                {!col8 ? null :
                                                    <tr>
                                                        <td></td>
                                                        <th style={{ resize: 'none' }} className="text-start">Estado </th>
                                                        <td className="text-start">{a.estado}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td></td>
                                                    <th style={{ resize: 'none' }} className="text-start">Actions </th>
                                                    <td className="text-center">
                                                        <div className="btn-group">

                                                            {/* Btn Modificar */}
                                                            <button
                                                                className="btn btn-sm btn-outline-info"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#ModifAdSof"
                                                                title="Modificar Item Seleccionado"
                                                                onClick={() => {
                                                                    setAdd(false)
                                                                    handleClickTraerArticulo(a)
                                                                }}>
                                                                <i className="fa-regular fa-pen-to-square" />
                                                            </button>

                                                            {/* Btn Eliminar */}
                                                            <button
                                                                className="btn btn-sm btn-outline-danger"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#EliminarSof"
                                                                title="Eliminar Item Seleccionado"
                                                                onClick={() => {
                                                                    setConsecutivoEliminar(a.consecutivo)
                                                                    handleObtenerURLImagen(a.listaImg, setImagenURLS, setCantImg)
                                                                }} >
                                                                <i className="fa-regular fa-trash-can" />
                                                            </button>

                                                            {/* Btn Imagen */}
                                                            <button className=" btn btn-sm btn-outline-success "
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#imgSof"
                                                                onClick={() => {
                                                                    handleObtenerURLImagen(a.listaImg, setImagenURLS, setCantImg)
                                                                    handleClickTraerArticulo(a)
                                                                    setColor("blue")
                                                                }} >
                                                                <i className="fa-regular fa-eye" />
                                                            </button>

                                                            {/* Btn Copiar*/}
                                                            <button className=" btn btn-sm btn-outline-secondary "
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#ModifAdSof"
                                                                title="Copiar Item Seleccionado"
                                                                onClick={() => {
                                                                    handleClickTraerArticulo(a)
                                                                    setCopy(true)
                                                                    setAdd(false)
                                                                }} >
                                                                <i className="fa-regular fa-copy" />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))}
                        </>
                        /* Mobile */
                        :
                        /* Desktop */
                        <div className="table-responsive">
                            <table id="myTable" className="table table-hover table-striped table-sm table-bordered align-middle">

                                <thead className="text-center">
                                    <tr>
                                        <th onClick={() => ordenar('consecutivoOk', countOrdenar, documentosFiltrados, setListaConsecSof, setCountOrdenar)}>Consec.</th>
                                        <th >Cant. Img</th>
                                        <th onClick={() => ordenar('categoria', countOrdenar, documentosFiltrados, setListaConsecSof, setCountOrdenar)} className={col2 ? '' : 'd-none'}>Categoria</th>
                                        <th onClick={() => ordenar('marca', countOrdenar, documentosFiltrados, setListaConsecSof, setCountOrdenar)} className={col3 ? '' : 'd-none'}>Marca</th>
                                        <th onClick={() => ordenar('ref', countOrdenar, documentosFiltrados, setListaConsecSof, setCountOrdenar)} className={col4 ? '' : 'd-none'}>Referencia</th>
                                        <th onClick={() => ordenar('nombre', countOrdenar, documentosFiltrados, setListaConsecSof, setCountOrdenar)} className={col5 ? '' : 'd-none'}>Nombre</th>
                                        <th onClick={() => ordenar('descripcion', countOrdenar, documentosFiltrados, setListaConsecSof, setCountOrdenar)} className={col6 ? '' : 'd-none'}>Descripcion</th>
                                        <th onClick={() => ordenar('valor', countOrdenar, documentosFiltrados, setListaConsecSof, setCountOrdenar)} className={col7 ? '' : 'd-none'}>Valor</th>
                                        <th onClick={() => ordenar('estado', countOrdenar, documentosFiltrados, setListaConsecSof, setCountOrdenar)} className={col8 ? '' : 'd-none'}>Estado</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>

                                <tbody className="text-center" id="tabla_articulos">
                                    {registrosPaginaActual.length === 1 && registrosPaginaActual[0].consecutivo === '' ? null
                                        : registrosPaginaActual?.map((a) => (
                                            <tr key={a.consecutivo + a.id}>
                                                <td>{a.consecutivoOk}</td>
                                                <td>{a.listaImg.length}</td>
                                                <td className={col2 ? '' : 'd-none'}>{a.categoria}</td>
                                                <td className={col3 ? '' : 'd-none'}>{a.marca}</td>
                                                <td className={col4 ? '' : 'd-none'}>{a.ref}</td>
                                                <td className={col5 ? '' : 'd-none'}>{a.nombre}</td>
                                                <td className={col6 ? '' : 'd-none'}>{a.descripcion}</td>
                                                <td className={col7 ? '' : 'd-none'}>
                                                    ${Intl.NumberFormat("es-CO").format(a.valor)}
                                                </td>
                                                <td className={col8 ? '' : 'd-none'}>{a.estado}</td>

                                                {/* Btns */}
                                                <td className="text-center">
                                                    <div className="btn-group">

                                                        {/* Btn Modificar */}
                                                        <button
                                                            className="btn btn-sm btn-outline-info"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#ModifAdSof"
                                                            title="Modificar Item Seleccionado"
                                                            onClick={() => {
                                                                setAdd(false)
                                                                setCopy(false)
                                                                handleClickTraerArticulo(a)
                                                            }}>
                                                            <i className="fa-regular fa-pen-to-square" />
                                                        </button>

                                                        {/* Btn Eliminar */}
                                                        {/* <button
                                                            className="btn btn-sm btn-outline-danger"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#EliminarSof"
                                                            title="Eliminar Item Seleccionado"
                                                            onClick={() => {
                                                                setConsecutivoEliminar(a.consecutivo)
                                                                handleObtenerURLImagen(a.listaImg, setImagenURLS, setCantImg)
                                                            }} >
                                                            <i className="fa-regular fa-trash-can" />
                                                        </button> */}

                                                        {/* Btn Imagen */}
                                                        <button className=" btn btn-sm btn-outline-success "
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#imgSof"
                                                            title="Imagenes Item Seleccionado"
                                                            onClick={() => {
                                                                handleObtenerURLImagen(a.listaImg, setImagenURLS, setCantImg)
                                                                handleClickTraerArticulo(a)
                                                                setColor("blue")
                                                            }} >
                                                            <i className="fa-regular fa-eye" />
                                                        </button>

                                                        {/* Btn Copiar */}
                                                        <button className=" btn btn-sm btn-outline-secondary "
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#ModifAdSof"
                                                            title="Copiar Item Seleccionado"
                                                            onClick={() => {
                                                                handleClickTraerArticulo(a)
                                                                setCopy(true)
                                                                setAdd(false)
                                                            }} >
                                                            <i className="fa-regular fa-copy" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        /* Desktop */
                    }

                    <Pagination
                        paginaActual={paginaActual}
                        totalPaginas={totalPaginas}
                        setPaginaActual={setPaginaActual}
                        registrosPorPagina={registrosPorPagina}
                        setRegistrosPorPagina={setRegistrosPorPagina}
                        documentosFiltrados={documentosFiltrados}
                    />

                    {/* + Modal de Modificar // Adicionar monitores*/}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="ModifAdSof"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >

                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className={add ? "modal-header bg-primary text-light justify-content-center" : "modal-header bg-info text-secondary justify-content-center"}>
                                    <h5 className="modal-title fs-5">
                                        {add && !copy ? "Crear" : !add && !copy ? "Modificar" : "Copiar"} Software
                                    </h5>
                                </div>
                                {/* Formulario */}
                                <div className="modal-body">

                                    {/* Consecutivo */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Consecutivo:</span>
                                        {consecOk ?
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={add || copy ? verConsecSof : consecutivo}
                                                disabled />
                                            :
                                            <input
                                                className="form-control text-danger"
                                                type="text"
                                                value={'Sin consecutivo dipsonible'}
                                                disabled />}
                                    </div>

                                    {/* Categoria  */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Categoria:</span>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={categoria}
                                            disabled />
                                    </div>

                                    {/* Marca  */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Marca: </span>
                                        {marcasOk ?
                                            <select
                                                className="form-select"
                                                value={marca}
                                                disabled={copy}
                                                onChange={(e) => {
                                                    setMarca(e.target.value)
                                                }}>
                                                <option value="" disabled>Seleccione</option>
                                                {listaMarcas.map((m, index) => (
                                                    <option key={index} value={m.marca}>
                                                        {m.marca}
                                                    </option>
                                                ))}
                                            </select>
                                            : <input
                                                className="form-control text-danger"
                                                type="text"
                                                value={"Verifique las marcas"}
                                                disabled />}
                                    </div>

                                    {/* url imagen*/}
                                    {add || copy ?
                                        <>
                                            <div className="input-group input-group-sm mb-2">
                                                <span className="input-group-text">Imágenes <span className="text-danger mx-1"> (Min.1)</span>: </span>
                                                <input type="file" accept="image/*" multiple
                                                    onChange={(e) => {
                                                        handleImageSelect(e, 2)
                                                        setFechaCreacion(fechaFormateada)
                                                        setUsuarioCreacion(user)
                                                        setConsecutivo(verConsecSof)
                                                    }}
                                                    className='form-control' ref={inputFile} />
                                            </div>
                                            {copy && !fileOk ? <p className="text-danger fs-6" >Debe seleccionar la imagen del Software</p> : null}
                                        </> : null}

                                    {/* Referencia */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Referencia:</span>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={ref}
                                            disabled={copy}
                                            onChange={(e) => {
                                                setRef(e.target.value.toUpperCase())
                                                setConsecutivo(add ? verConsecSof : consecutivo)
                                            }} />
                                    </div>

                                    {/* Nombre  */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Nombre:</span>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={nombre}
                                            disabled={copy}
                                            onChange={(e) => {
                                                setNombre(e.target.value.toUpperCase())
                                            }} />
                                    </div>

                                    {/* Descripcion */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Descripcion: </span>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={descripcion}
                                            disabled={copy}
                                            onChange={(e) => {
                                                setDescripcion(e.target.value.toUpperCase())
                                            }} />
                                    </div>

                                    {/* Valor */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Valor Venta: </span>
                                        <input
                                            className="form-control"
                                            onFocus={() => { add && setValor("") }}
                                            value={valor}
                                            disabled={copy}
                                            type="number"
                                            min={1}
                                            onChange={(e) => setValor(e.target.value)} />
                                    </div>

                                    {/* Estado */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Estado: </span>
                                        <select
                                            className="form-select"
                                            value={estado}
                                            disabled={copy}
                                            onChange={(e) => setEstado(e.target.value)}>
                                            <option value="" disabled>Seleccione</option>
                                            <option value="ACTIVO">Activo</option>
                                            <option value="INACTIVO">Inactivo</option>
                                        </select>
                                    </div>

                                </div>

                                <div className="modal-footer">
                                    {/* {consecOk && marcasOk && fileOk ? */}
                                        <button
                                            type="button"
                                            className={add ? "btn btn-sm btn-primary text-light" : "btn btn-sm btn-info text-secondary"}
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                add || copy ?
                                                    handleClickAdicionar() :
                                                    handleClickModificarBd("Art")
                                            }}>
                                            {add && !copy ? "Crear" : !add && !copy ? "Modificar" : "Guardar Copia"}
                                        </button>
                                        {/* : null} */}

                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            resetStates()
                                            setListImgSelec([])
                                        }} >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal de Modificar // Adicionar */}

                    {/* +Modal Eliminar */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="EliminarSof"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >

                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className="modal-header bg-danger text-white justify-content-center">
                                    <h5 className="modal-title  " id="staticBackdropLabel3">
                                        Alerta Eliminar Software
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <div className="modal-body text-center">
                                        <p>
                                            Está seguro de eliminar el <strong>Software</strong> <br /> con consecutivo: <strong> {' ' + consecutivoEliminar}</strong>, <br />
                                            <span className="text-danger mt-2">
                                                Recuerde que esta acción no se puede revertir.
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        data-bs-dismiss="modal"
                                        id="btnEliminarArticulo"
                                        onClick={() => handleClickEliminar()} >
                                        Si, eliminar
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal" >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal Eliminar */}

                    {/* +Modal Imagenes */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="imgSof"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false">

                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content ">

                                <div className={color === "grey"
                                    ? "modal-header bg-secondary text-light justify-content-center "
                                    : color === "blue"
                                        ? "modal-header bg-primary text-light justify-content-center "
                                        : color === "info"
                                            ? "modal-header bg-info text-light justify-content-center"
                                            : color === "red"
                                                ? "modal-header bg-danger text-light justify-content-center"
                                                : null}
                                    style={{ maxHeight: "60px" }}>

                                    <h5 className="my-3 p-0">{color === "grey" ? "Cargar Imágen del " : color === "blue" ? "Imágenes del " : color === "info" ? "Cambiar Imágen del " : color === "red" ? " Eliminar Imágen del " : null} Artículo </h5>

                                </div>

                                <div className="modal-body">

                                    {/* Tabla Imgs */}
                                    {!addImg && !deleteImg && !updateImg ?
                                        <>
                                            <div className="table-responsive">
                                                <table className="table table-hover table-striped table-sm table-bordered align-middle">

                                                    <thead>
                                                        <tr>
                                                            <th>Imágen</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {imagenURLS.map((i) => (
                                                            <tr key={i.ruta}>
                                                                <td>
                                                                    <img src={i.url} alt="Imágen artículo" style={{ "width": "20%", "height": "20%" }} />
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="btn-group">

                                                                        <button className="btn bnt-sm btn-outline-primary"
                                                                            title="Cambiar Imagen"
                                                                            onClick={() => {
                                                                                setUpdateImg(true)
                                                                                setImgActualizar(i)
                                                                                setDeleteImg(false)
                                                                                setAddImg(false)
                                                                                setColor("info")
                                                                            }} >
                                                                            <i className="fa-regular fa-pen-to-square " />
                                                                        </button>

                                                                        <button className="btn btn-sm btn-outline-danger"
                                                                            title="Eliminar Imagen"
                                                                            onClick={() => {
                                                                                setDeleteImg(true)
                                                                                setImgBorrar(i)
                                                                                setUpdateImg(false)
                                                                                setAddImg(false)
                                                                                setColor("red")
                                                                            }} >
                                                                            <i className="fa-solid fa-trash-can" />
                                                                        </button>

                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                                {/* Cargar nueva imagen */}
                                                <button className="btn bnt-sm btn-secondary  my-1"
                                                    disabled={!cantImg >= 5}
                                                    title="Agregar imágen nueva"
                                                    onClick={() => {
                                                        setAddImg(true)
                                                        setUpdateImg(false)
                                                        setDeleteImg(false)
                                                        setColor("grey")
                                                    }} >
                                                    <i className="fa-solid fa-plus" />
                                                </button>
                                            </div>
                                        </>
                                        :
                                        // Nueva Img
                                        addImg && !updateImg && !deleteImg ?
                                            <>
                                                <div className="container mt-1">
                                                    <div className="row justify-content-center">
                                                        <label className="col-sm-12 form-label">
                                                            Seleccione su nueva imágen:
                                                        </label>
                                                        <div className="col-sm-12">
                                                            <div className="input-group mb-3">
                                                                <input type="file" accept="image/*"
                                                                    className='form-control'
                                                                    onChange={(e) => handleImageSelect(e, 1, setImgNew, 'software', consecutivo, setListImgSelec, inputFile, setListUrlImg, setRutaEnFirestore, imgActualizar, verConsecSof)}
                                                                    ref={inputFile} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Btns  Nueva Img */}
                                                    <div className="row text-center mt-4">
                                                        <div className="col">
                                                            {imgNew ?
                                                                <button className="btn btn-sm btn-dark "
                                                                    type="button"
                                                                    title="Guardar Nueva Imágen"
                                                                    data-bs-dismiss="modal"
                                                                    onClick={() => nuevaImagen(1)} >
                                                                    <i className="fa-solid fa-floppy-disk" />
                                                                </button>
                                                                : null}
                                                        </div>

                                                        <div className="col">
                                                            <button
                                                                className="btn btn-sm btn-secondary "
                                                                title="Cancelar" onClick={() => {
                                                                    limpiar(setUpdateImg, setDeleteImg, setAddImg, setCantImg)
                                                                    setColor("blue")
                                                                }} >
                                                                <i className="fa-solid fa-ban" />
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            // Actualizar Img
                                            updateImg && !deleteImg && !addImg ?
                                                <>
                                                    <div className="container mt-1">
                                                        <div className="row justify-content-center">

                                                            <p className="mb-3">La Imágen Actual</p>
                                                            <img src={imgActualizar.url} alt="Mi imagen" style={{
                                                                "display": "block", "marginLeft": "auto",
                                                                "marginRight": "auto", "width": "30%",
                                                                "height": "30%", "marginBottom": "10px"
                                                            }} />

                                                            <hr className="w-75" />

                                                            <label className="col-sm-12 form-label">
                                                                Seleccione su nueva imágen
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <div className="input-group mb-3">
                                                                    <input type="file" accept="image/*"
                                                                        className='form-control'
                                                                        multiple
                                                                        onChange={(e) => handleImageSelect(e, 3, setImgNew, 'software', consecutivo, setListImgSelec, inputFile, setListUrlImg, setRutaEnFirestore, imgActualizar, verConsecSof)}
                                                                        ref={inputFile} />
                                                                </div>
                                                            </div>

                                                            {/* Btns */}
                                                            <div className="row text-center mt-3">
                                                                <div className="col">
                                                                    {imgNew ?
                                                                        <button className="btn btn-sm btn-info "
                                                                            type="button"
                                                                            data-bs-dismiss="modal"
                                                                            title="Modificar la Imágen"
                                                                            onClick={() => {
                                                                                handleActualizarImagen(imgNew, rutaEnFirestore, setImgNew, resetStates)
                                                                                limpiar(setUpdateImg, setDeleteImg, setAddImg, setCantImg)
                                                                            }} >
                                                                            <i className="fa-solid fa-rotate-right" />
                                                                        </button>
                                                                        : null}
                                                                </div>

                                                                <div className="col">
                                                                    <button
                                                                        className="btn btn-sm btn-secondary "
                                                                        title="Cancelar" onClick={() => {
                                                                            limpiar(setUpdateImg, setDeleteImg, setAddImg, setCantImg)
                                                                            setColor("blue")
                                                                        }} >
                                                                        <i className="fa-solid fa-ban" />
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                // Eliminar imagen 
                                                deleteImg && !updateImg && !addImg ?
                                                    <>
                                                        <div className="container mt-3">

                                                            <div className="row  justify-content-center">
                                                                <p className="mb-3"> Esta seguro de eliminar esta imágen? <br /><span className="text-danger">Recuerde que esta accion no se puede revertir.</span></p>

                                                                <img src={imgBorrar.url} alt="Mi imagen" style={{
                                                                    "display": "block", "marginLeft": "auto",
                                                                    "marginRight": "auto", "width": "30%",
                                                                    "height": "30%", "marginBottom": "10px"
                                                                }} />

                                                                <hr className="w-75" />

                                                                {/* Btns  Borrar Img*/}
                                                                <div className="row text-center mt-2">

                                                                    <div className="col">
                                                                        <div className="col">
                                                                            <button className="btn btn-sm btn-danger"
                                                                                data-bs-dismiss="modal" title="Confirmar Eliminacion Imágen" onClick={() => {
                                                                                    handleClickModificarBd("DelImg")
                                                                                    handleEliminarImagen(1, imgBorrar, resetStates, imagenURLS)
                                                                                    limpiar(setUpdateImg, setDeleteImg, setAddImg, setCantImg)
                                                                                }} >
                                                                                <i className="fa-regular fa-trash-can" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="col">
                                                                            <button
                                                                                className="btn btn-sm btn-secondary "
                                                                                title="Cancelar" onClick={() => {
                                                                                    limpiar(setUpdateImg, setDeleteImg, setAddImg, setCantImg)
                                                                                    setColor("blue")
                                                                                }} >
                                                                                <i className="fa-solid fa-ban" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : null}
                                </div>

                                {!addImg && !deleteImg && !updateImg ? <>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-primary"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                setListImgSelec([])
                                                setImagenURLS([])
                                                limpiar(setUpdateImg, setDeleteImg, setAddImg, setCantImg)
                                            }}>
                                            Cancelar
                                        </button>
                                    </div>
                                </> : null}
                            </div>
                        </div>
                    </div >
                    {/* -Modal Imagenes */}

                    {/* +Modal Columnas */}
                    <ModalCoumnas
                        columnas={['Categoria', 'Marca', 'Referencia', 'Nombre', 'Descripcion', 'Valor', 'Estado']}
                        identificador={'soft'}
                        col2={col2} col3={col3} col4={col4} col5={col5} col6={col6} col7={col7} col8={col8}
                        setCol2={setCol2} setCol3={setCol3} setCol4={setCol4} setCol5={setCol5} setCol6={setCol6} setCol7={setCol7}
                        setCol8={setCol8} />
                </>
            }
        </>
    )
}
